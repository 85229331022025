<template lang="pug">
  header#header
    .inner
      router-link#logo(to="/admin/home/")
        img(src="@/assets/logo-horizontal-1.svg")
      .sp-menu(:class="{isVisible: burgerVisible}")
        nav.nav
          router-link.nav__link(to="/admin/estimate/" :class="{isSeeing: seeing == 'estimate'}")
            span.ja 見積情報
            span.en Estimate
          span.nav__separator
          router-link.nav__link(to="/admin/item/" :class="{isSeeing: seeing == 'item'}")
            span.ja 商品情報
            span.en Item List
          span.nav__separator
          router-link.nav__link(to="/admin/settings/" :class="{isSeeing: seeing == 'settings'}")
            span.ja 設定
            span.en Settings
          span.nav__separator
          router-link.nav__link(to="/admin/category/" :class="{isSeeing: seeing == 'category'}")
            span.ja カテゴリ情報
            span.en Category

        input.search(
          type="search"
          placeholder="見積書を検索"
          v-model="searchWord"
          @keydown.enter="search($event)"
        )

        .logout(@click="overlayState = 'logout' ")
          img(src="@/assets/icon-logout.svg")
      .btns
        .btns-item.burger(@click="burgerVisible = !burgerVisible")
          span.burger-line
            span.line
            span.line
            span.line
          span.ja メニュー
      transition(name="fade")
        overlay(
          v-if="overlayState == 'logout'"
          type="logout"
          icon="exclamation.svg"
          ttl="ご確認下さい"
          msg="ログアウトしますか？"
          additionalBtn=false
          additionalBtnLink=""
          additionalBtnTxt=""
          @overlayOff="overlayOff"
          @deleteSubmit="logout"
        )

</template>

<script>
import ApiClient from "@/lib/http.js";
import overlay from "@/components/common/overlay.vue";
export default {
  name: "Header",
  components: {
    overlay,
  },
  props: {
    msg: String,
    seeing: String,
  },
  data: function () {
    return {
      burgerVisible: false,
      overlayState: "",
      searchWord: "",
    };
  },
  methods: {
    //overlay からのイベント
    ////モーダル解除
    overlayOff: function () {
      this.overlayState = false;
    },
    async logout() {
      const api = new ApiClient();
      let result = await api.get("/admin/logout");
      if (result.state == 200) {
        this.$router.push("/admin/login/");
      }
    },
    //検索ボックス
    async search(event) {
      console.log(event.isComposing);
      if (event.isComposing) {
        console.log("isComposing");
        return;
      }

      console.log("search");
      //空じゃなければ/admin/estimate/search/へ送る
      if (this.searchWord != "") {
        console.log("if");
        this.$router.push("/admin/estimate/search/" + this.searchWord);
      } else {
        console.log("else");
        this.$router.push("/admin/estimate/");
      }
    },
  },
};
</script>
<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.logout {
  @include mixin.liquidSize(width, 19, 38);
  @include mixin.liquidSize(height, 25, 50);
  @include mixin.liquidSize(margin-left, 45, 0);
  @include mixin.liquidSize(margin-right, 15, 0);
  @include mixin.liquidSize(margin-top, 0, 60);
  cursor: pointer;
  @include media.min-screen(media.$breakpoint-md) {
    transition: all 0.4s ease-in-out;
    &:hover {
      opacity: 0.4;
    }
  }
}

#header {
  width: 100%;
  @include mixin.liquidSize(height, 100, 100);
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.inner {
  //@include mixin.liquidSize(max-width, 1320, 750);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 35, 30);
}
#logo {
  @include mixin.liquidSize(width, 133, 133);
  @include mixin.liquidSize(height, 62, 62);
  img {
    width: 100%;
  }
  @include media.min-screen(media.$breakpoint-md) {
    transition: all 0.4s ease-in-out;
    &:hover {
      opacity: 0.4;
    }
  }
}

.sp-menu {
  display: flex;
  @include media.max-screen(media.$breakpoint-sm) {
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transform: scale(0) translate(0, 10%);
    width: 100%;
    height: 100%;
    background: #fff;
    transition: all 0.3s ease-in-out;
    position: fixed;
    left: 0;
    top: calc(100 / 750 * 100vw);
    z-index: 100;
    &.isVisible {
      opacity: 1;
      pointer-events: all;
      transform: scale(1) translate(0, 0%);
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    align-items: center;
    width: 100%;
  }
}
.nav {
  display: flex;
  @include mixin.liquidSize(padding-top, 0, 100);
  @include media.max-screen(media.$breakpoint-sm) {
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    align-items: center;
  }
  @include media.min-screen(media.$breakpoint-md) {
    margin: 0 auto;
    align-items: center;
  }
}

@keyframes seeing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.nav__link {
  color: #000;
  @include mixin.liquidSize(padding-left, 35, 30);
  @include mixin.liquidSize(padding-right, 35, 30);
  @include mixin.liquidSize(padding-top, 0, 50);
  @include mixin.liquidSize(padding-bottom, 0, 50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include mixin.liquidSize(height, 100, 100);
  position: relative;
  &:before {
    content: "";
    width: 0;
    height: 2px;
    background: #1890a7;
    position: absolute;
    right: 0%;
    bottom: 0;
    transition: all 0.4s ease-in-out 0.1s;
  }
  &.isSeeing {
    &:before {
      animation: seeing 0.4s ease-in-out 0.1s forwards;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    &:hover {
      &:before {
        width: 100%;
        left: 0;
      }
    }
  }
  .ja {
    @include mixin.liquidSize(font-size, 15, 22);
    letter-spacing: -0.005em;
    font-weight: 400; /* regular */
    margin-bottom: 3px;
  }
  .en {
    @include mixin.liquidSize(font-size, 10, 20);
    color: #989898;
    letter-spacing: -0.015em;
    font-weight: 400; /* regular */
  }
}
.nav__separator {
  width: 1px;
  height: 60px;
  @include mixin.liquidSize(width, 1, 500);
  @include mixin.liquidSize(height, 60, 1);
  @include media.max-screen(media.$breakpoint-sm) {
    border-bottom: dashed 1px #cecece;
  }
  @include media.min-screen(media.$breakpoint-md) {
    border-right: dashed 1px #cecece;
  }
}

.search {
  background: {
    image: url(../../../assets/icon-search.svg);
    size: 21px auto;
    repeat: no-repeat;
    position: center right 15px;
  }
  width: 217px;
  height: 41px;
  border-radius: 21px;
  box-sizing: border-box;
  border: solid 1px #a2a2a2;
  padding: 0 28px;
  @include mixin.liquidSize(font-size, 14, 22);
  position: relative;
  &::-webkit-search-cancel-button {
    position: absolute;
    left: 15px;
    cursor: pointer;
  }
}
.btns {
  display: flex;
  align-items: center;
  @include media.max-screen(media.$breakpoint-sm) {
    margin-left: auto;
  }
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: 24px;
  }
  .separator {
    border-right: dashed 1px #cecece;
    @include mixin.liquidSize(height, 70, 70);
  }
}
.btns-item {
  @include mixin.liquidSize(width, 100, 120);
  @include mixin.liquidSize(height, 70, 70);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include media.max-screen(media.$breakpoint-sm) {
    &.contact {
      display: none;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    &.burger {
      display: none;
    }
  }
  .icon,
  .burger-line {
    @include mixin.liquidSize(width, 27, 27);
    @include mixin.liquidSize(height, 22, 22);
  }
  .ja {
    @include mixin.liquidSize(font-size, 12, 20);
    color: #000;
    font-weight: 500; /* medium */
    //transform: scale(0.75);
  }
}

.burger-line {
  display: flex;
  flex-direction: column;
  .line {
    background: #707070;
    height: 1px;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      @include mixin.liquidSize(width, 0, 18);
      margin-bottom: 3px;
    }
    &:nth-of-type(3) {
      @include mixin.liquidSize(width, 0, 14);
    }
  }
}
</style>
