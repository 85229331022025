<template lang="pug">
  section.category
    Header(seeing="settings")
    main.admin
      .m-content-2
        breadcrumb(
          h2txt="設定"
          h2link=""
          h3txt=""
          h3link=""
          type="admin"
        )
        head3(ja="設定" en="Settings")


        section.form
          .form-style-1
            //- 管理者メールアドレス：
            .row
              span.label.required 必須
              span.head 管理者メールアドレス：
              input.adminMail(type="text" v-model="settings.adminMail")
              .error {{ errors.mailaddress ? errors.mailaddress[0] : '' }}

            //- 法人割引率：
            .row.discount-blk
              span.label.required 必須
              span.head 法人割引率：
              input.discount(type="text" v-model="settings.discount")
              |%
              .error {{ errors.corporate_discount ? errors.corporate_discount[0] : '' }}

            //- 見積書定形文：
            .row.estimate-blk
              span.label.required 必須
              span.head 見積書定形文：
              textarea.estimate(v-model="settings.estimate" placeholder="例：お支払い：銀行振込（前払い）\n※前日までに入金の確認ができるようお願いいたします。\n※お引取り時のお支払いにも対応できますので、ご希望の場合は事前にご相談下さい。")
              .error {{ errors.fixed_phrase ? errors.fixed_phrase[0] : '' }}
              .note
                span.icon
                  img(src="@/assets/icon-q.svg")
                |見積書に入る定型文を入力下さい。

            //- 仮見積書メール定型文
            .row.pre-blk
              p.block-head 仮見積書メール定型文
              span.label.required 必須
              span.head タイトル：
              textarea.preTitle(v-model="settings.preTitle")
              .error {{ errors.estimate_title ? errors.estimate_title[0] : '' }}
              span.label.required 必須
              span.head 本文：
              textarea.preDesc(v-model="settings.preDesc")
              .error {{ errors.estimate_body ? errors.estimate_body[0] : '' }}
              .note
                span.icon
                  img(src="@/assets/icon-q.svg")
                |仮見積もり作成後の自動送信メールに入る定型文を入力下さい。

            //- 正式見積書メール定型文
            .row.fixed-blk
              p.block-head 正式見積書メール定型文
              span.label.required 必須
              span.head タイトル：
              textarea.fixedTitle(v-model="settings.fixedTitle")
              .error {{ errors.quotation_title ? errors.quotation_title[0] : '' }}
              span.label.required 必須
              span.head 本文：
              textarea.fixedDesc(v-model="settings.fixedDesc")
              .error {{ errors.quotation_body ? errors.quotation_body[0] : '' }}
              .note
                span.icon
                  img(src="@/assets/icon-q.svg")
                |正式見積もり作成後の自動送信メールに入る定型文を入力下さい。

            //- 休日設定：
            .row.holiday-blk
              span.label.required 必須
              span.head 休日設定：
              transition-group.holidayItem-blk(name="fade" tag="div")
                .holidayItem-row(v-for="holiday, index in settings.holiday" :key="index")
                  datepicker.holidayItem(:language="ja" format="yyyy年MM月dd日(D)" v-model="settings.holiday[index].date" @closed="pickerClosed(index)" @opened="pickerChange(index)" :class="{'has-value': settings.holiday[index].date != ''}")
                  .delete-btn(@click="deleteHolidayValue(index)")
                    |×
                  .inner-btns(v-if="index + 1 === settings.holiday.length")
                    btn(
                      v-if="1 < settings.holiday.length"
                      @click="deleteHoliday(settings.holiday.length)"
                      btnTxt="削除する"
                      color="white"
                      size="m"
                    )
                    btn(
                      @click="addHoliday"
                      btnTxt="さらに追加する"
                      color="white"
                      size="m"
                    )

              span.label.required 必須
              span.head 本文：
              textarea.holidayDesc(v-model="settings.holidayDesc")
              .error {{ errors.holiday_description ? errors.holiday_description[0] : '' }}
              .note
                span.icon
                  img(src="@/assets/icon-q.svg")
                |休日に対する説明文を入力して下さい。

        .btns
          btn(
            btnTxt="戻る"
            color="white"
            size="s"
            @click="back"
          )
          btn(
            btnTxt="保存する"
            color="primary"
            size="l"
            @click="editSetting"
          )





</template>

<script>
import Header from "@/components/admin/common/header.vue";
import head3 from "@/components/common/head-3.vue";
import btn from "@/components/admin/common/btn-1.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import { common } from "@/mixins/common.js";
import ApiClient from "@/lib/http.js";
import dayjs from "dayjs";
export default {
  name: "Settings",
  metaInfo() {
    return {
      title: "設定 | 関西でイベント備品のレンタルならカリトク",
      meta: [
        { name: "description", content: "カリトクはイベント運営のプロ集団「株式会社ノーレイジ」が運営する、イベント備品レンタルサービスです。大規模フェスやコンサート、大手企業の社内イベントや大規模会議など、これまで数々の催し事を運営してきた私たちだからこそできる、細かいアドバイスや提案が可能なカリトクは、従来の貸すだけのサービスではなく、お客様の催し事が成功することまで一緒にサポートさせていただくことで好評を得ております。" },
        { name: "keywords", content: "" },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  components: {
    Header,
    head3,
    btn,
    breadcrumb,
    datepicker,
  },
  mixins: [common],
  computed: {
    imgDisp() {
      const imgList = this.item.img;
      return imgList.sort((a, b) => a.order - b.order);
    },
  },
  data: function () {
    return {
      ja: ja,
      draggingItem: null,
      settings: {
        adminMail: "",
        discount: null,
        estimate: "",
        preTitle: "",
        preDesc: "",
        fixedTitle: "",
        fixedDesc: "",
        holiday: [
          {
            date: "",
          },
        ],
        holidayDesc: "",
      },
      holidays: {
        add: [],
        delete: [],
      },
      errors: {},
    };
  },
  async created() {
    this.getSetting();
    this.getHoliday();
  },
  methods: {
    async getSetting() {
      const api = new ApiClient();
      let data = await api.get("/admin/setting");
      this.settings.adminMail = data.detail.mailaddress;
      this.settings.discount = data.detail.corporate_discount;
      this.settings.estimate = data.detail.fixed_phrase;
      this.settings.preTitle = data.detail.estimate_title;
      this.settings.preDesc = data.detail.estimate_body;
      this.settings.fixedTitle = data.detail.quotation_title;
      this.settings.fixedDesc = data.detail.quotation_body;
      this.settings.holidayDesc = data.detail.holiday_description;
    },
    async getHoliday() {
      let now = new Date();
      let nowYear = now.getFullYear();
      let nowManth = now.getMonth() + 1;
      nowManth = ("00" + nowManth).slice(-2);
      let param = {
        date: nowYear + "-" + nowManth,
      };
      const api = new ApiClient();
      let data = await api.post("/admin/holiday", param);
      console.log(data.list);
      for (let i = 0; i < data.list.length; i++) {
        if (i == 0) {
          this.settings.holiday = [];
        }
        this.settings.holiday.push({
          date: data.list[i].holiday,
        });
      }
    },
    pickerClosed(index) {
      console.log(
        dayjs(this.settings.holiday[index].date).format("YYYY-MM-DD")
      );
      this.settings.holiday[index].date = dayjs(
        this.settings.holiday[index].date
      ).format("YYYY-MM-DD");
    },

    pickerChange(index) {
      console.log(index);
    },

    addHoliday: function () {
      this.settings.holiday.push({
        date: "",
      });
    },

    deleteHoliday: function (index) {
      this.holidays.delete.push({
        date: this.settings.holiday[index - 1].date,
      });
      let i = index - 1;
      this.settings.holiday.splice(i, 1);
    },

    deleteHolidayValue: function (index) {
      this.holidays.delete.push({
        date: this.settings.holiday[index].date
      });
      this.settings.holiday[index].date = "";
    },

    async editSetting() {
      // this.settings.holiday[n].date が空の場合は削除する
      for (let i = 0; i < this.settings.holiday.length; i++) {
        if (this.settings.holiday[i].date == "") {
          this.holidays.delete.push({
            date: this.settings.holiday[i].date,
          });
          this.settings.holiday.splice(i, 1);
        }
      }
      let param = {
        mailaddress: this.settings.adminMail,
        corporate_discount: this.settings.discount,
        fixed_phrase: this.settings.estimate,
        estimate_title: this.settings.preTitle,
        estimate_body: this.settings.preDesc,
        quotation_title: this.settings.fixedTitle,
        quotation_body: this.settings.fixedDesc,
        holiday_description: this.settings.holidayDesc,
        holidays: {
          add: this.settings.holiday,
          delete: this.holidays.delete,
        },
        //holidays: this.settings.holiday
      };
      console.log(param);
      const api = new ApiClient();
      let data = await api.post("/admin/setting/save", param);
      console.log(data);
      if (data.state == 204) {
        this.errors = data.errors;
        this.windowTop();
        return;
      }
      this.$router.push("/admin/home");
    },

    dragstart(item, e) {
      this.draggingItem = item; // ドラッグ中の要素を保持
      e.dataTransfer.effectAllowed = "move"; // 移動モードに設定
      e.target.style.opacity = 0.5; // ドラッグ中要素のスタイルを変更
    },
    dragenter(item) {
      // ドラッグ中の要素とドラッグ先の要素の表示順を入れ替える
      [item.order, this.draggingItem.order] = [
        this.draggingItem.order,
        item.order,
      ];
    },
    dragover(e) {
      e.dataTransfer.dropEffect = "move"; // 移動モードに設定
    },
    dragend(e) {
      e.target.style.opacity = 1; // ドラッグ中要素のスタイルを変更（元に戻す）
      this.draggingItem = null; // ドラッグ中の要素をクリア
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.head-blk {
  @include mixin.liquidSize(margin-bottom, 65, 40);
}

.btns {
  display: flex;
  width: 100%;
  @include mixin.liquidSize(width, 1012, 700);
  @include mixin.liquidSize(margin-bottom, 30, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: flex-end;
  }
  .button {
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px 20px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      margin: 0 0 0 20px;
    }
  }
}

.note {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 32px;
  box-sizing: border-box;
  line-height: 1.75;
  color: #6f6f6f;
  @include mixin.liquidSize(margin-top, 5, 10);
  @include mixin.liquidSize(font-size, 13, 23);
  min-height: 22px;
  @include media.min-screen(media.$breakpoint-md) {
    width: 100%;
    //margin-left: auto;
  }
  .icon {
    width: 22px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.form {
  background: #fcfcfc;
  @include mixin.liquidSize(width, 1185, 700);
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 105, 40);
  @include mixin.liquidSize(padding-right, 105, 40);
  padding-top: 55px;

  // 行
  .row {
    padding: 25px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include mixin.liquidSize(font-size, 14, 22);
    &:not(:last-of-type) {
      border-bottom: dotted 1px #dedede;
    }
  }

  .label,
  .head {
    @include mixin.liquidSize(margin-bottom, 0, 30);
  }

  .label {
    width: 41px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mixin.liquidSize(font-size, 13, 22);
    border-radius: 4px;
    color: #fff;
    margin-right: 14px;
    background: #b7b7b7;
    &.required {
      background: #e57e7e;
    }
  }

  .block-head {
    width: 100%;
    @include mixin.liquidSize(font-size, 19, 26);
    font-weight: 700; /* bold */
    @include mixin.liquidSize(margin-bottom, 20, 30);
  }
  .head {
    @include mixin.liquidSize(font-size, 17, 26);
    letter-spacing: 0.04em;
    @include mixin.liquidSize(margin-right, 15, 20);
  }
  .form-style-1 {
    @include mixin.liquidSize(margin-bottom, 40, 60);
    @include mixin.liquidSize(width, 843, auto);
    margin-left: auto;
    margin-right: auto;
  }

  .inner-btns {
    display: flex;
    width: 100%;
    @include mixin.liquidSize(margin-top, 30, 30);
    button {
      @include mixin.liquidSize(margin-right, 30, 40);
      @include mixin.liquidSize(width, 167, 270);
    }
  }

  .inner-blk {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include mixin.liquidSize(font-size, 14, 22);
  }

  .size-blk,
  .code-blk {
    @include mixin.liquidSize(width, 465, 620);
    @include mixin.liquidSize(margin-bottom, 10, 40);
  }

  .price-blk {
    @include mixin.liquidSize(width, 375, 620);
    @include mixin.liquidSize(margin-bottom, 10, 40);
  }

  .discount-blk {
    .note {
      @include mixin.liquidSize(width, 500, 620);
      @include mixin.liquidSize(margin-left, 45, 0);
    }
  }

  .estimate-blk,
  .pre-blk,
  .fixed-blk,
  .holiday-blk {
    .label {
      @include media.min-screen(media.$breakpoint-md) {
        margin-top: 12px;
        margin-bottom: auto;
      }
    }
    .head {
      @include media.min-screen(media.$breakpoint-md) {
        margin-top: 9px;
        margin-bottom: auto;
      }
    }
    .note {
      @include media.min-screen(media.$breakpoint-md) {
        width: 630px;
        margin-left: auto;
      }
    }
  }

  .spec-blk {
    .label {
      @include media.min-screen(media.$breakpoint-md) {
        margin-top: 12px;
        margin-bottom: auto;
      }
    }
    .head {
      @include media.min-screen(media.$breakpoint-md) {
        margin-top: 9px;
        margin-bottom: auto;
      }
    }
  }

  .spec-item {
    @include mixin.liquidSize(width, 630, 620);
    &:not(:first-of-type) {
      @include mixin.liquidSize(margin-top, 40, 30);
      margin-left: auto;
    }
  }

  input {
    &.adminMail {
      width: 578px;
      max-width: 100%;
    }
    &.discount {
      @include mixin.liquidSize(width, 78, 400);
      text-align: right;
      margin-right: 10px;
      @include media.min-screen(media.$breakpoint-md) {
        margin-left: 37px;
      }
    }
  }

  textarea {
    &.attention {
      @include mixin.liquidSize(width, 630, 620);
      @include mixin.liquidSize(height, 152, 300);
      @include media.min-screen(media.$breakpoint-md) {
        margin-left: auto;
      }
    }
    &.preTitle,
    &.fixedTitle {
      @include mixin.liquidSize(width, 630, 620);
      @include mixin.liquidSize(height, 81, 150);
      @include mixin.liquidSize(margin-bottom, 30, 40);
      @include media.min-screen(media.$breakpoint-md) {
        margin-left: auto;
      }
    }
    &.estimate,
    &.preDesc,
    &.fixedDesc,
    &.holidayDesc {
      @include mixin.liquidSize(width, 630, 620);
      @include mixin.liquidSize(height, 197, 300);
      @include media.min-screen(media.$breakpoint-md) {
        margin-left: auto;
      }
    }
  }

  .holidayItem-blk {
    @include mixin.liquidSize(width, 630, 620);
    @include mixin.liquidSize(margin-bottom, 30, 40);
    @include media.min-screen(media.$breakpoint-md) {
      margin-left: auto;
    }
  }

  .holidayItem-row {
    position: relative;
    @include media.min-screen(media.$breakpoint-md) {
      display: flex;
    }
    .inner-btns {
      @include mixin.liquidSize(margin-top, 0, 40);
      @include mixin.liquidSize(margin-left, 30, 0);
    }
  }

  .holidayItem {
    @include mixin.liquidSize(margin-bottom, 30, 40);
    &.has-value {

      + .delete-btn {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  .delete-btn {
    position: absolute;
    @include mixin.liquidSize(top, 12, 24);
    @include mixin.liquidSize(left, 167, 320);
    background: #e00;
    background:#6f6f6f;
    color: #fff;
    border-radius: 50%;
    @include mixin.liquidSize(width, 23, 46);
    @include mixin.liquidSize(height, 23, 46);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .4s ease;
    opacity: 0;
    pointer-events: none;
    @include media.min-screen(media.$breakpoint-md) {
      width: 23px;
      height: 23px;
      top: 12px;
      left: 167px;
    }
    &:hover {
      background: #343434;
    }
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
</style>
