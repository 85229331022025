<template lang="pug">
  .cart
    main.user
      Header
      .content
        .m-content
          breadcrumb(
            h2txt="見積編集"
            h2link=""
            h3txt=""
            h3link=""
            type="admin"
          )


          cart(
            @changePeriod="getLeftDays"
            @update="update"
            @deleteItem="deleteItem"
            @periodError="periodError"
            :cartItem="estimate.carts"
            :errors="errors"
            v-model="estimate"
            :attribute="estimate.attribute"
            type="admin"
            :send_price="estimate.send_price"
            :support_price="estimate.support_price"
            :pick_up="estimate.pick_up"
            :give_back="estimate.give_back"
            )

          .edit-btn
            btn(
              btnTxt="商品を追加する"
              color="primary"
              size="l"
              @click="overlayState = 'add2cart', saveAPI()"
            )



          #form
            .form-item
              label.label.require 必須
              span.head 仮予約：
              .labels
                label
                  span
                  input(type="radio" name="reserve" value="1" @click="reserveState = true" v-model="estimate.reserve")
                  span
                  |する
                label
                  input(type="radio" name="reserve" value="0" @click="reserveState = false" v-model="estimate.reserve")
                  span
                  |しない
              .error {{ errors.reserve ? errors.reserve[0] : ''}}
            .form-item.eventTitle
              .gr-1
                label.label.require 必須
                span.head 催事タイトル：
              .gr-2
                input(type="text" name="eventTitle" placeholder="カリトクフェスティバル" v-model="estimate.eventTitle")
            .form-item
              .inner-item
                label.label.require 必須
                span.head 属性：
                .labels
                  label
                    span
                    input(type="radio" name="company" v-model="estimate.attribute" value="1")
                    span
                    |法人
                  label
                    input(type="radio" name="company"  v-model="estimate.attribute" value="2")
                    span
                    |個人
                .error {{ errors.attribute ? errors.attribute[0] : ''}}
              .inner-item.name
                label.label.optional 任意
                span.head 宛名：
                input(type="text" name="name" placeholder="株式会社カリトク 総務部御中" v-model="estimate.address")
                .error {{ errors.address ? errors.address[0] : ''}}
                .labels
                  label
                    span
                    input(type="radio" name="title" v-model="estimate.honorific_title" value="1")
                    span
                    |様
                  label
                    input(type="radio" name="title" v-model="estimate.honorific_title" value="2")
                    span
                    |御中
                  .error {{ errors.honorific_title ? errors.honorific_title[0] : ''}}

            .form-item
              .inner-item
                label.label.optional(v-if="!reserveState") 任意
                label.label.require(v-if="reserveState") 必須
                span.head 電話：
                input(type="tel" name="tel" placeholder="090-1111-2222" v-model="estimate.tel")
                .error {{ errors.tel ? errors.tel[0] : ''}}
              .inner-item
                label.label.require 必須
                span.head メール：
                input(type="mail" name="mail" placeholder="info@kari-toku.jp" v-model="estimate.mailaddress")
                .error {{ errors.mailaddress ? errors.mailaddress[0] : ''}}

            .form-item.delivery
              .gr-1
                label.label.require 必須
                span.head 受け渡し方法：
              .gr-2
                .gr-2__item
                  span.head （開始時）
                  .labels
                    label
                      span
                      input(type="radio" name="deliveryStart" v-model="estimate.pick_up" value="1")
                      span
                      |引き取り
                    label
                      input(type="radio" name="deliveryStart" v-model="estimate.pick_up" value="2")
                      span
                      |配送・郵送を希望する
                  .error {{ errors.pick_up ? errors.pick_up[0] : ''}}
                .gr-2__item
                  span.head （返却時）
                  .labels
                    label
                      span
                      input(type="radio" name="deliveryEnd" v-model="estimate.give_back" value="1")
                      span
                      |来社
                    label
                      input(type="radio" name="deliveryEnd" v-model="estimate.give_back" value="2")
                      span
                      |配送・郵送を希望する
                  .error {{ errors.give_back ? errors.give_back[0] : ''}}

            .form-item(v-show="estimate.pick_up == 2 || estimate.give_back == 2")
              .inner-item.shippingCost-row
                label.label.require 必須
                span.head 送料：
                input(type="text" name="shippingCost" v-model="estimate.send_price")
                |円
                .error {{ errors.send_price ? errors.send_price[0] : ''}}

            .form-item
              .inner-item.supportFee-row
                label.label.require 必須
                span.head 組み立てサポート：
                input(type="text" name="supportFee" v-model="estimate.support_price")
                |円
                .error {{ errors.support_price ? errors.support_price[0] : ''}}

            .form-item.purpose
              label.label.require 必須
              span.head 利用目的（備考）：
              .textarea-blk
                textarea(name="purpose" placeholder="" v-model="estimate.purpose")
                span.placeholder(v-if="estimate.purpose == ''") 20xx年xx月xx日〜XX月XX日の3日間で、フードイベントを開催する予定。
                  br
                  |など、可能でしたら具体的にお教えください。

            .form-item(v-for="item, index in estimate.addFields" :key="item.name")
              span.additional-head(v-if="index < 1") 追加項目
              .inner-item.additionalItem-row
                label.label.optional 任意
                input(type="text" name="additionalItem" v-model="estimate.addFields[index].name")
                input(type="text" name="additionalItemFee" v-model="estimate.addFields[index].price")
                |円

            .btns
              btn(
                btnTxt="戻る"
                color="white"
                size="s"
                @click="back"
              )
              btn(
                btnTxt="正式見積として保存"
                color="white"
                size="m"
                @click="save"
              )
              router-link(:to="'/admin/estimate/confirm/' + estimate.id")
                btn(
                  btnTxt="メール作成"
                  color="primary"
                  size="l"
                  @click="saveAPI"
                )

    transition(name="fade")
      overlay(
        v-if="overlayState == 'error'"
        type="error"
        icon="submit.png"
        ttl="入力内容をご確認ください。"
        :msg="errorMsg"
        additionalBtn=false
        @overlayOff="overlayOff"
      )
      #overlay(v-if="overlayState == 'add2cart'")
        .overlay-content
          .overlay-content__scroll-area
            .catList
              .sort
                span.label カテゴリで絞り込み：
                //-.sort-item {{ filteredCat }}
              .cat-item(@click="changeCat('全て')" :class="{'is-selected': filteredCat == '全て'}") 全て

              .cat-item(v-for="cat, index in category" :key="index" @click="changeCat(cat.title)" :class="{'is-selected': filteredCat == cat.title}") {{ cat.title }}

            .item-blk
              .item(v-for="item in items" :key="item.id")
                .item-img
                  img(:src="item.img")
                .name {{ item.name }} {{item.id}}

                .size
                  .size-item(v-for="size in item.details" :key="size.id")
                    span.size-name {{ size.size }}
                    span.price {{ (Number(size.price) + Number(Math.floor( size.price * (item.after_second_day / 100)))).toLocaleString() }}
                      span.currency 円(税込)
                    .size-btn
                      .minus(@click="minus(size)")
                      //-.num {{ size.amount }}
                      input.num(type="number" v-model="size.amount" min="0")
                      .plus(@click="plus(size)")
                    .support
                      label(v-if="item.support == 1")
                        input(type="checkbox" class="suport-check" v-model="size.item_support" true-value="1" false-value="0")
                        .support-toggle
          .overlay-btn
            btn.overlay-btn__item(
              btnTxt="閉じる"
              color="white"
              size="s"
              @click="overlayState = false"
            )
            btn.overlay-btn__item(
              btnTxt="見積りカートに追加"
              color="primary"
              size="m"
              @click="add2cart"
            )
</template>

<script>
// @ is an alias to /src
import Header from "@/components/admin/common/header.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import cart from "@/components/user/cart/cart.vue";
import btn from "@/components/admin/common/btn-1.vue";
import { common } from "@/mixins/common.js";
import ApiClient from "@/lib/http.js";
import overlay from "@/components/common/overlay.vue";
export default {
  name: "Edit",
  metaInfo() {
    return {
      title: "見積編集 | 関西でイベント備品のレンタルならカリトク",
      meta: [
        { name: "description", content: "カリトクはイベント運営のプロ集団「株式会社ノーレイジ」が運営する、イベント備品レンタルサービスです。大規模フェスやコンサート、大手企業の社内イベントや大規模会議など、これまで数々の催し事を運営してきた私たちだからこそできる、細かいアドバイスや提案が可能なカリトクは、従来の貸すだけのサービスではなく、お客様の催し事が成功することまで一緒にサポートさせていただくことで好評を得ております。" },
        { name: "keywords", content: "" },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  components: {
    Header,
    breadcrumb,
    cart,
    btn,
    overlay,
  },
  mixins: [common],
  data: function () {
    return {
      reserveState: false,
      leftDays: 0,
      category: [],
      filtered: false,
      filteredCat: "全て",
      allItems: [],
      items: [],
      addItems: [],
      //見積りデータ
      estimate: {
        id: "",
        cart: [],
        start_date: "",
        end_date: "",
        reserve: "",
        eventTitle: "",
        attribute: "",
        address: "",
        honorific_title: "",
        tel: "",
        mailaddress: "",
        pick_up: "",
        give_back: "",
        tax: 0,
        subtotal: 0,
        fix_price: 0,
        send_price: 0,
        support_price: 0,
        addFields: [],
      },
      errors: {},
      errorMsg: "",
      overlayState: false,
    };
  },
  async created() {
    await this.getCategory();
    await this.getEstimate();
    await this.getAddItems();
  },
  watch: {

  },
  methods: {
    addFreeItem: function() {
      console.log("addFreeItem");
      //this.estimate.carts に追加
      this.estimate.carts.push({
        //after_second_day_price: "100",
        amount: "1",
        //discount: "10",
        //item_detail_id: "100000000",
        //item_support: "1",
        name: "フリーアイテム",
        price: "1000",
        //size: "",
        //support: "0"
      });
    },
    //overlay からのイベント
    overlayOff: function () {
      this.overlayState = false;
    },
    //cart からのイベント
    getLeftDays: function (v) {
      this.leftDays = v;
      console.log("getLeftDays");
    },
    //レンタル期間が0以下
    periodError: function () {
      console.log("periodError");
      this.overlayState = "error";
      this.errorMsg = "終了日は開始日以降に設定してください";
    },
    async deleteItem(id) {
      let param = {
        id: id,
      };
      console.log(id)
      const api = new ApiClient();
      await api.post("/admin/cart/delete", param);
      this.getEstimate();
    },
    async update(item) {
      console.log({item})
      /*let param = {
        id: item.id,
        amount: item.amount,
        support: item.support,
      };
      const api = new ApiClient();
      await api.post("/cart/update", param, false);*/
    },
    async getEstimate() {
      if (this.$route.params.id > 0) {
        console.log("getEstimate");
        const api = new ApiClient();
        let data = await api.get(
          "/admin/estimate/detail/" + this.$route.params.id
        );
        this.estimate = data.detail;
      }
    },
    async saveAPI() {
      console.log("saveAPI");
      const api = new ApiClient();
      let data = await api.post("/admin/estimate/save", this.estimate);
      if (data.state == 204) {
        console.log(data);
        this.errors = data.errors;
        this.errorMsg = "";
        for (let key in this.errors) {
          for (let i = 0; i < this.errors[key].length; i++) {
            var label = "";
            if (key == "reserve") label = "仮予約：";
            if (key == "eventTitle") label = "催事タイトル：";
            if (key == "attribute") label = "属性：";
            if (key == "tel") label = "電話番号：";
            if (key == "mailaddress") label = "メールアドレス：";
            if (key == "pick_up") label = "受け渡し方法(開始時)：";
            if (key == "give_back") label = "受け渡し方法(返却時)：";
            if (key == "purpose") label = "利用目的を";
            if (key == "honorific_title") label = "宛名：";
            if (key == "purpose" && this.errors[key][i] == "入力してください") {
              this.errorMsg += label + "2000文字以内で入力してください。" + "<br>";
            } else {
              this.errorMsg += label + this.errors[key][i] + "<br>";
            }

          }
        }
        this.windowTop();
        this.overlayState = "error";
        return false;
      }
      return true;
    },
    async save() {
      const success = await this.saveAPI();
      if (!success) {
        return;
      }
      //待ってからrouter.push
      window.setTimeout(() => {
        console.log("saveTimer");
        this.$router.push("/admin/estimate");
      }, 200);

    },
    //追加用の商品一覧
    async getAddItems() {
      if (this.$route.params.id > 0) {
        const api = new ApiClient();
        let data = await api.get("/admin/item_detail/list/s");
        console.log(data)
        this.allItems = data.list;
        this.items = data.list;
        console.log(this.allItems);
      }

    },
    plus: function (item) {
      item.amount++;
    },
    minus: function (item) {
      item.amount--;
      if (item.amount < 0) {
        item.amount = 0;
      }
    },
    async add2cart() {
      for(let i = 0; i < this.items.length; i++) {
        for(let j = 0; j < this.items[i].details.length; j++) {
          if(this.items[i].details[j].amount > 0) {
            this.addItems.push({
              id: this.items[i].details[j].id,
              amount: this.items[i].details[j].amount,
              item_support: this.items[i].details[j].item_support,
            });
          }
        }
      }
      const api = new ApiClient();
      let param = {
        details: this.addItems,
        support: 0,
        estimate_id: this.$route.params.id,
      }
      await api.post("/admin/cart/add", param);
      this.getEstimate();
      this.overlayState = "false";
    },
    async getCategory() {
      const api = new ApiClient();
      let data = await api.get("/admin/category");
      this.category = data.list;
    },

    async changeCat(catName) {
      console.log("changeCat()");
      if(catName == "全て") {
        this.getAddItems();
      } else {
        //items の中からカテゴリー名が一致するものを抽出
        let items = [];
        for(let i = 0; i < this.allItems.length; i++) {
          if(this.allItems[i].category == catName) {
            items.push(this.allItems[i]);
          }
        }
        this.items = items;
      }
      this.filteredCat = catName;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

.content {
  background: #fafafa;
  @include mixin.liquidSize(padding, 55, 40);
}

//フォーム
#form {
  @include mixin.liquidSize(width, 954, 700);
  background: #fff;
  box-sizing: border-box;
  @include mixin.liquidSize(padding, 55, 40);
  @include mixin.liquidSize(margin-bottom, 110, 100);
}

.form-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: dotted 1px #c7c7c7;
  @include mixin.liquidSize(padding-top, 25, 0);
  @include mixin.liquidSize(padding-bottom, 25, 0);
  @include mixin.liquidSize(min-height, auto, 90);

  .inner-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    @include mixin.liquidSize(padding-top, 0, 20);
    @include mixin.liquidSize(padding-bottom, 0, 20);
    @include mixin.liquidSize(min-height, auto, 90);
    @include media.max-screen(media.$breakpoint-sm) {
      width: 100%;
      &:not(:last-of-type) {
        border-bottom: dotted 1px #c7c7c7;
      }
    }
    //例外 宛名
    &.name {
      .labels {
        @include mixin.liquidSize(margin-left, 0, 200);
      }
    }
    //例外 送料、組み立てサポート
    &.shippingCost-row,
    &.supportFee-row {
      @include mixin.liquidSize(font-size, 14, 24);
      .head {
        @include mixin.liquidSize(width, 170, 270);
      }
    }
    //例外 追加項目
    .additionalItem-row {
      @include mixin.liquidSize(font-size, 14, 24);
    }
  }

  .label {
    color: #fff;
    @include mixin.liquidSize(font-size, 13, 20);
    @include mixin.liquidSize(width, 41, 60);
    @include mixin.liquidSize(height, 20, 32);
    @include mixin.liquidSize(margin-right, 8, 10);
    @include mixin.liquidSize(margin-bottom, 0, 0);
    @include mixin.liquidSize(border-radius, 4, 8);
    white-space: nowrap;
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 7, 10);
    @include mixin.liquidSize(padding-right, 6, 8);
    @include mixin.liquidSize(padding-top, 0, 1);
    @include mixin.liquidSize(padding-bottom, 1, 0);
    line-height: 1;
  }
  .require {
    background: #e57e7e;
  }
  .optional {
    background: #a5a5a5;
  }
  .head {
    @include mixin.liquidSize(font-size, 17, 26);
    @include mixin.liquidSize(margin-right, 10, 0);
    @include mixin.liquidSize(width, auto, 120);
    line-height: 1;
  }
  //例外 催事タイトル
  &.eventTitle {
    flex-wrap: wrap;
    @include mixin.liquidSize(padding-top, 25, 20);
    @include mixin.liquidSize(padding-bottom, 25, 20);
    .gr-1 {
      display: flex;
      align-content: center;
      @include mixin.liquidSize(margin-bottom, 0, 20);
      @include media.max-screen(media.$breakpoint-sm) {
        width: 100%;
      }
      .head {
        @include mixin.liquidSize(width, auto, auto);
      }
    }
    .gr-2 {
      box-sizing: border-box;
      @include mixin.liquidSize(padding-left, 0, 190);
    }
  }
  //例外 受渡方法
  &.delivery {
    @include mixin.liquidSize(padding-top, 40, 35);
    @include mixin.liquidSize(padding-bottom, 10, 15);
    .gr-1 {
      display: flex;
      align-items: center;
      @include mixin.liquidSize(margin-bottom, auto, 40);
    }
    .head {
      @include mixin.liquidSize(width, auto, auto);
    }
    .gr-2 {
      .gr-2__item {
        display: flex;
        align-items: center;
        @include mixin.liquidSize(width, auto, auto);
        &:not(last-of-type) {
          @include mixin.liquidSize(margin-bottom, 25, 30);
        }
        label {
          @include mixin.liquidSize(margin-right, 20, 20);
        }
        .head {
          @include mixin.liquidSize(margin-right, 10, 22);
        }
      }
    }
  }
  //例外 利用目的
  &.purpose {
    align-items: flex-start;
    @include mixin.liquidSize(padding-top, 25, 20);
    @include mixin.liquidSize(padding-bottom, 25, 20);
    width: 100%;
    .label {
      @include mixin.liquidSize(margin-top, 10, 10);
    }
    .head {
      @include mixin.liquidSize(width, 170, 150);
      @include mixin.liquidSize(margin-top, 7, 5);
      line-height: 1.5;
    }
    .textarea-blk {
      position: relative;
      .placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        @include mixin.liquidSize(padding-top, 20, 30);
        @include mixin.liquidSize(padding-left, 20, 30);
        @include mixin.liquidSize(padding-right, 20, 30);
        color: #A09090;
        @include mixin.liquidSize(font-size, 15, 22);
        pointer-events: none;
      }

      textarea {
        @include mixin.liquidSize(font-size, 15, 22);
        background: #f5f6f8;
        @include mixin.liquidSize(width, 615, 400);
        @include mixin.liquidSize(height, 150, 200);
        box-sizing: border-box;
        @include mixin.liquidSize(padding-top, 20, 30);
        @include mixin.liquidSize(padding-left, 20, 30);
        @include mixin.liquidSize(padding-right, 20, 30);
        @include mixin.liquidSize(border-radius, 6, 6);
      }
    }
  }
}

.labels {
  display: flex;
  align-items: center;
}
label {
  display: flex;
  align-items: center;
  @include mixin.liquidSize(font-size, 14, 24);
  @include mixin.liquidSize(margin-right, 14, 62);
  cursor: pointer;
  position: relative;
}

input {
  + span {
    border: solid 1px #adadad;
    @include mixin.liquidSize(width, 20, 26);
    @include mixin.liquidSize(height, 20, 26);
    display: inline-block;
    border-radius: 50%;
    position: relative;
    @include mixin.liquidSize(margin-right, 12, 12);
    box-sizing: border-box;
  }
  &:checked {
    + span {
      &:after {
        content: "";
        @include mixin.liquidSize(width, 12, 16);
        @include mixin.liquidSize(height, 12, 16);
        display: inline-block;
        border-radius: 50%;
        background: #1890a7;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &[type="text"],
  &[type="mail"],
  &[type="tel"] {
    @include mixin.liquidSize(font-size, 15, 22);
    background: #f5f6f8;
    @include mixin.liquidSize(height, 45, 62);
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 20, 20);
    @include mixin.liquidSize(padding-right, 20, 20);
    @include mixin.liquidSize(border-radius, 6, 6);
    &[readonly="readonly"] {
      background: none;
      border-bottom: dotted 1px #707070;
      @include mixin.liquidSize(border-radius, 0, 0);
      pointer-events: none;
    }
  }
  &[name="name"] {
    @include mixin.liquidSize(width, 325, 430);
    @include mixin.liquidSize(margin-right, 15, 0);
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  &[name="tel"] {
    @include mixin.liquidSize(width, 260, 430);
    @include mixin.liquidSize(margin-right, 38, 0);
  }
  &[name="mail"] {
    @include mixin.liquidSize(width, 309, 430);
  }
  &[name="shippingCost"],
  &[name="supportFee"] {
    @include mixin.liquidSize(font-size, 17, 22);
    @include mixin.liquidSize(width, 131, 200);
    text-align: right;
    @include mixin.liquidSize(margin-right, 10, 10);
  }
  &[name="additionalItem"] {
    @include mixin.liquidSize(width, 144, 200);
    @include mixin.liquidSize(margin-right, 25, 30);
    @include mixin.liquidSize(font-size, 17, 22);
  }
  &[name="additionalItemFee"] {
    @include mixin.liquidSize(width, 131, 200);
    text-align: right;
    @include mixin.liquidSize(margin-right, 10, 10);
    @include mixin.liquidSize(font-size, 17, 22);
  }
  &[name="eventTitle"] {
    @include mixin.liquidSize(width, 325, 430);
  }
}

.notes {
  @include mixin.liquidSize(font-size, 14, 20);
  .head {
    @include mixin.liquidSize(font-size, 15, 20);
    @include mixin.liquidSize(margin-right, 0, 0);
  }
  li {
    &:not(:last-of-type) {
      @include mixin.liquidSize(margin-bottom, 15, 30);
    }
  }
  a {
    color: #1890a7;
    border-bottom: dotted 1px #1890a7;
  }
  .icon {
    @include mixin.liquidSize(width, 18, 29);
    @include mixin.liquidSize(height, 20, 32);
    @include mixin.liquidSize(margin-left, 6, 12);
    display: inline-block;
    img {
      width: 100%;
    }
  }
}

.btn {
  background: #1890a7;
  color: #fff;
  @include mixin.liquidSize(width, 280, 620);
  @include mixin.liquidSize(height, 55, 86);
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  @include mixin.liquidSize(padding-left, 55, 86);
  @include mixin.liquidSize(margin-top, 40, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    margin-left: auto;
    margin-right: auto;
  }
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: auto;
    &:hover {
      .icon {
        width: 100%;
      }
    }
  }
  .icon {
    @include mixin.liquidSize(width, 55, 86);
    @include mixin.liquidSize(height, 55, 86);
    background: #12869b;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.4s ease-in-out;
    @include mixin.liquidSize(padding-left, 16, 24);
    box-sizing: border-box;
    img {
      @include mixin.liquidSize(width, 18, 34);
    }
  }
  .txt {
    @include mixin.liquidSize(font-size, 14, 26);
    margin: 0 auto;
    letter-spacing: 0.035em;
    position: relative;
    z-index: 2;
  }
}

.btns {
  display: flex;
  width: 100%;
  @include mixin.liquidSize(margin-top, 40, 60);
  @include mixin.liquidSize(margin-bottom, 30, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: flex-end;
  }
  .button {
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px 20px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      margin: 0 0 0 20px;
    }
  }
}

.additional-head {
  width: 100%;
  display: block;
  @include mixin.liquidSize(font-size, 18, 26);
  font-weight: 600; /* semibold */
  @include mixin.liquidSize(padding-top, 0, 30);
  @include mixin.liquidSize(margin-bottom, 30, 30);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.edit-btn {
  display: flex;
  justify-content: center;
  @include mixin.liquidSize(width, 954, 700);

  @include mixin.liquidSize(margin-bottom, 60, 60);
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;

  //display:none;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  @include mixin.liquidSize(width, 1320, 700);
  height: 80%;
  z-index: 101;
  box-sizing: border-box;
  border-radius: 10px;
  @include mixin.liquidSize(padding-top, 55, 40);
  @include mixin.liquidSize(padding-bottom, 0, 0);
  @include mixin.liquidSize(padding-left, 65, 30);
  @include mixin.liquidSize(padding-right, 65, 30);

}

.overlay-content__scroll-area {
  height: 100%;
  overflow: scroll;
}

.catList {
  display: flex;
  flex-wrap: wrap;

  box-sizing: border-box;
  //border: solid 1px #dedede;
  background: #fafafa;

  @include mixin.liquidSize(padding-top, 30, 30);
  @include mixin.liquidSize(padding-bottom, 20, 20);
  @include mixin.liquidSize(padding, 30, 30);
  @include mixin.liquidSize(padding, 30, 30);
  @include mixin.liquidSize(margin-top, 30, 20);
  @include mixin.liquidSize(margin-bottom, 30, 20);
  .sort {
    display: flex;
    align-items: center;
    width: 100%;
    @include mixin.liquidSize(margin-bottom, 20, 20);
    .label {
      color: #737d7e;
      letter-spacing: 0.06em;
      @include mixin.liquidSize(font-size, 14, 22);
    }
  }
  .cat-item {
    @include mixin.liquidSize(font-size, 14, 22);
    padding: 0.5em 1.25em;
    @include mixin.liquidSize(margin-bottom, 10, 10);
    @include mixin.liquidSize(margin-right, 10, 10);
    border: solid 1px #dedede;
    box-sizing: border-box;
    background: #fff;
    border-radius: 3px;
    cursor: pointer;
    &.is-selected {
      background: #e5f9f9;
      border-color: #e5f9f9;
      color: #1890a7;
    }
  }
}

.item-blk {
  width: 100%;

  box-sizing: border-box;
  @include mixin.liquidSize(padding-bottom, 136, 136);
}
.item {
  display: flex;
  flex-wrap: wrap;
  @include mixin.liquidSize(margin-bottom, 50, 50);
  .item-img {
    @include mixin.liquidSize(width, 100, 200);
    @include mixin.liquidSize(min-height, 63, 126);
    @include mixin.liquidSize(margin-right, 30, 30);
    @include mixin.liquidSize(margin-bottom, 20, 20);
  }
  .name {
    @include mixin.liquidSize(font-size, 17, 17);
    letter-spacing: 0.04em;
  }
  .size {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .size-item {
    display: flex;
    align-items: center;
    @include mixin.liquidSize(width, 579, 300);
    border-bottom: dotted 1px #DEDEDE;
    @include mixin.liquidSize(padding-top, 10, 10);
    @include mixin.liquidSize(padding-bottom, 10, 10);
    position: relative;
    &:nth-of-type(even) {
      margin-left: auto;
    }
    &:nth-of-type(1),
    &:nth-of-type(2) {
      .support {
        &:after {
          content: "組み立て\Aサポート";
          white-space: pre;
          position: absolute;
          @include mixin.liquidSize(top, -10, -10 );
          left: 0;
          width: 100%;
          translate: 0 -100%;
          box-sizing: border-box;
          @include mixin.liquidSize(padding-right, 14, 62);
          @include mixin.liquidSize(margin-left, 24, 62);
        }
      }
    }
  }
  .size-name {
    @include mixin.liquidSize(width, 265, 265);
    @include mixin.liquidSize(font-size, 15, 30);
    letter-spacing: 0.04em;
    line-height: 1.5;
  }
  .price {
    color: #1890a7;
    @include mixin.liquidSize(font-size, 13, 24);
    @include mixin.liquidSize(width, 115, 230);
    text-align: right;
    .currency {
      @include mixin.liquidSize(font-size, 11, 20);
    }
    .after {
      display: inline-block;
      @include mixin.liquidSize(margin-top, 10, 10);
      @include mixin.liquidSize(font-size, 13, 20);
    }
  }
  .size-btn {
    display: flex;
    align-items: center;
    @include mixin.liquidSize(width, 110, 110);
    @include mixin.liquidSize(height, 30, 30);
    @include mixin.liquidSize(border-radius, 15, 15);
    border: solid 1px #848484;
    box-sizing: border-box;
    @include mixin.liquidSize(margin-top, auto, 0);
    @include mixin.liquidSize(margin-left, 20, 20);
    &.nochange {
      pointer-events: none;
    }
    .num {
      box-sizing: border-box;
      border-left: dotted 1px #dedede;
      border-right: dotted 1px #dedede;
      @include mixin.liquidSize(font-size, 14, 28);
      color: #848484;
      @include mixin.liquidSize(width, 50, 50);
      @include mixin.liquidSize(height, 26, 26);
      @include mixin.liquidSize(padding-right, 0, 0);
      @include mixin.liquidSize(padding-top, 2, 0);
      @include mixin.liquidSize(padding-bottom, 0, 3);
      text-align: right;
    }
    .plus,
    .minus {
      @include mixin.liquidSize(width, 30, 30);
      height: 100%;
      position: relative;
      cursor: pointer;
      &:before,
      &:after {
        content: "";
        background: #707070;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .plus {
      &:before,
      &:after {
        left: 40%;
      }
      &:before {
        @include mixin.liquidSize(width, 13, 13);
        height: 1px;
      }
      &:after {
        @include mixin.liquidSize(height, 13, 13);
        width: 1px;
      }
    }
    .minus {
      &:before {
        left: 60%;
        @include mixin.liquidSize(width, 13, 13);
        height: 1px;
      }
    }
  }

  //-
  .support {
    @include mixin.liquidSize(width, 77, 80);
    display: flex;
    align-items: center;
    position: relative;
    &.nochange {
      pointer-events: none;
    }
    label {
      @include mixin.liquidSize(height, 20, 30);
      margin-left: auto;
    }
  }

  .support-toggle {
    @include mixin.liquidSize(width, 35, 56);
    @include mixin.liquidSize(height, 20, 30);
    @include mixin.liquidSize(border-radius, 10, 15);
    background: #939393;
    position: relative;
    transition: all 0.3s ease-in-out 0.1s;
    cursor: pointer;
    @include mixin.liquidSize(margin-left, 20, 20);
    &:before {
      content: "";
      @include mixin.liquidSize(width, 20, 30);
      @include mixin.liquidSize(height, 20, 30);
      border-radius: 50%;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      transition: all 0.3s ease-in-out 0.1s;
    }
  }

  .suport-check {
    display: none;
    &:checked {
      + .support-toggle {
        background: #1890a7;
        &:before {
          @include mixin.liquidSize(left, 15, 26);
        }
      }
    }
  }
}

.overlay-btn {
  width: 100%;
  @include mixin.liquidSize(height, 136, 136);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  box-sizing: border-box;
  border-top: solid 1px #DEDEDE;
}

.overlay-btn__item {
  @include mixin.liquidSize(margin-left, 12, 12);
  @include mixin.liquidSize(margin-right, 12, 12);
}

</style>
