<!-- 日英 横並び -->
<template lang="pug">
  ul.breadcrumb

    li.item(v-if="type == 'admin'")
      router-link.item.hasLink(to="/admin/home/") ホーム
    li.item(v-else)
      router-link.item.hasLink(to="/") ホーム
    span.separator / 
    li.item(v-if="h2txt" :class="{ hasLink: h2link, noLink: !h2link}")
      router-link(:to="{ path: h2link}") {{h2txt}}
    span.separator(v-if="h3txt") / 
    li.item(v-if="h3txt" :class="{ hasLink: h3link, noLink: !h3link}")
      router-link(:to="{ path: h3link}") {{h3txt}}

</template>

<script>
export default {
  name: "head-1",
  props: {
    h1txt: String,
    h1link: String,
    h2txt: String,
    h2link: String,
    h3txt: String,
    h3link: String,
    type: String,
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.breadcrumb {
  display: flex;
  @include mixin.liquidSize(margin-top, 25, 25);
  @include mixin.liquidSize(margin-bottom, 30, 50);
}

.separator {
  @include mixin.liquidSize(font-size, 14, 20);
  @include mixin.liquidSize(margin-right, 5, 5);
}
.item {
  @include mixin.liquidSize(font-size, 14, 20);
  &.hasLink {
    color: #1990a7;
    border-bottom: dotted 1px #1990a7;
    @include media.min-screen(media.$breakpoint-md) {
      transition: all 0.4s ease-in-out;
      &:hover {
        opacity: 0.6;
      }
    }
    a {
      color: #1990a7;
    }
  }
  &.noLink {
    pointer-events: none;
    color: #000;
    a {
      color: #000;
    }
  }
  &:not(:last-of-type) {
    @include mixin.liquidSize(margin-right, 7, 7);
  }
}
</style>
