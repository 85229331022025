<template lang="pug">

  main.user
    Header
    section.kv

    section.content
      .m-content
        .breadcrumb
          breadcrumb(
            h2txt="運動会"
            h2link=""
            h3txt=""
            h3link=""
          )

        section
          h2.head
            head2(ja="運動会" en="Sports Festival")
          p.desc


          itemList(
            ja="テント"
            en="Tent"
            keyword="tent"
            :list="tent"
            )

          itemList(
            ja="幕・横断幕"
            en="Curtain"
            keyword="curtain"
            :list="curtain"
            )

          itemList(
            ja="椅子"
            en="Chair"
            keyword="chair"
            :list="chair"
          )

          itemList(
            ja="テーブル"
            en="Table"
            keyword="table"
            :list="table"
          )

          //-itemList(
            ja="儀式(葬儀・地鎮祭)"
            en="Funeral/Ground Breaking"
            keyword="funeral"
            :list="funeral"
            )

          itemList(
            ja="夏季商品"
            en="Summer"
            keyword="summer"
            :list="summer"
            )

          itemList(
            ja="冬季商品"
            en="Winter"
            keyword="winter"
            :list="winter"
            )

          itemList(
            ja="ステージ・スロープ"
            en="Stage / Slope"
            keyword="stage"
            :list="stage"
            )

          itemList(
            ja="電灯・照明・電気機器"
            en="Light"
            keyword="light"
            :list="light"
            )

          itemList(
            ja="イベント用品・その他"
            en="Event / Other"
            keyword="event"
            :list="event"
          )

          itemList(
            ja="会場整理用品"
            en="Venue"
            keyword="venue"
            :list="venue"
            )

          //-itemList(
            ja="抽選機・玉"
            en="Lottery"
            keyword="lottery"
            :list="lottery"
            )

          //-itemList(
            ja="式典・テープカット用品"
            en="Ceremony"
            keyword="ceremony"
            :list="ceremony"
            )

          //-itemList(
            ja="金屏風"
            en="Folding Screen"
            keyword="foldingscreen"
            :list="foldingscreen"
            )

          itemList(
            ja="敷物類シート関連"
            en="Rag"
            keyword="rag"
            :list="rag"
          )

          itemList(
            ja="運動会用品"
            en="Sports Festival"
            keyword="sports"
            :list="sports"
          )

          itemList(
            ja="パネル・パーテンション"
            en="Panel"
            keyword="panel"
            :list="panel"
            )












    section#scene
      .m-content
        h3.head
          head1(ja="シーン別に探す" en="Scene" type="h")
      scene

    section#flow
      flow
    contact
    Footer
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import flow from "@/components/user/common/flow.vue";
import contact from "@/components/user/common/contact.vue";
import scene from "@/components/user/common/scene.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import head2 from "@/components/common/head-2.vue";
import bnrs from "@/components/user/common/bnrs.vue";
import head1 from "@/components/common/head-1.vue";
import ApiClient from "@/lib/http.js";
import itemList from "@/components/user/scene/itemList.vue";
export default {
  name: "Festival",
  metaInfo() {
    return {
      title: "運動会を開催するならカリトクで備品レンタル",
      meta: [
        { name: "description", content: "カリトクはイベント運営のプロ集団「株式会社ノーレイジ」が運営する、イベント備品レンタルサービスです。大規模フェスやコンサート、大手企業の社内イベントや大規模会議など、これまで数々の催し事を運営してきた私たちだからこそできる、細かいアドバイスや提案が可能なカリトクは、従来の貸すだけのサービスではなく、お客様の催し事が成功することまで一緒にサポートさせていただくことで好評を得ております。" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    contact,
    Footer,
    breadcrumb,
    head2,
    bnrs,
    flow,
    scene,
    head1,
    itemList,
  },
  data: function () {
    return {
      tent: [],
      ceremony: [],
      curtain: [],
      chair: [],
      table: [],
      summer: [],
      winter: [],
      stage: [],
      light: [],
      event: [],
      venue: [],
      lottery: [],
      foldingscreen: [],
      rag: [],
      panel: [],
      funeral: [],
      sports: [],
    };
  },
  computed: {
    loadImg(path) {
      return require("@/assets/" + path);
    },
  },
  async created() {
    this.getTent();
    this.getCurtain();
    this.getChair();
    this.getTable();
    //this.getFuneral();
    this.getSummer();
    this.getWinter();
    this.getStage();
    this.getLight();
    this.getEvent();
    this.getVenue();
    //this.getLottery();
    //this.getCeremony();
    //this.getFoldingscreen();
    this.getRag();
    this.getPanel();
    this.getSports();
  },
  methods: {
    async getTent() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "TP-001",
          "TP-002",
          "TP-003",
          "TP-005",
          "TP-006",
          "TP-011",
          "TP-023",
          "TP-101",
          "TP-102",
          "TP-103",
          "TP-105",
          "TP-106",
          "TP-111",
          "TP-123",
          "TP-911",
          "TP-912",
          "TP-913",
          "TP-921",
          "TP-922",
          "TP-923",
          "IB-011",
          "IB-018",
          "IB-016",
          "TP-091",
          "TP-092",
          "TP-093",
          "TP-094",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.tent = data.list;
    },
    async getCurtain() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "FW-010",
          "FW-020",
          "FW-030",
          "FW-050",
          "MA-630",
          "MA-650",
          "MK-630",
          "MK-650",
          "MK-750",
          "FC-001",
          "FC-030",
          "FC-050",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.curtain = data.list;
    },
    async getChair() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "CP-001",
          "CP-002",
          "CP-004",
          "CP-005",
          "CP-006",
          "CP-007",
          "CP-008",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.chair = data.list;
    },
    async getTable() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "TB-002",
          "TB-003",
          "TB-004",
          "TB-005",
          "TB-010",
          "TB-011",
          "TB-201",
          "TB-301",
          "TB-302",
          "TK-001",
          "TK-002",
          "TK-003",
          "TK-004",
          "TK-101",
          "TB-057",
          "TB-058",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.table = data.list;
    },
    async getFuneral() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "J1-001",
          "J1-003",
          "JI-004",
          "JI-010",
          "JI-005",
          "JI-006",
          "J1-007",
          "J1-008",
          "J1-009",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.funeral = data.list;
    },
    async getSummer() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "SS-002",
          "SS-003",
          "SS-053",
          "SS-054",
          "SS-055",
          "IB-456",
          "IB-457",
          "BI-459",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.summer = data.list;
    },
    async getWinter() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: ["WS-002", "WS-020"],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.winter = data.list;
    },
    async getStage() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "ST-102",
          "ST-104",
          "ST-091",
          "ST-093",
          "ST-094",
          "ST-001",
          "ST-002",
          "ST-003",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.stage = data.list;
    },
    async getLight() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "DS-010",
          "DK-002",
          "DK-004",
          "DL-030",
          "IB-601",
          "DS-011",
          "DK-006",
          "DK-009",
          "DK-008",
          "DK-010",
          "DK-020",
          "DK-023",
          "DK-024",
          "DS-001",
          "DS-003",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.light = data.list;
    },
    async getEvent() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "CB-020",
          "CB-021",
          "CB-025",
          "CB-026",
          "CB-052",
          "CB-053",
          "CB-051",
          "AT-007",
          "AT-008",
          "AT-009",
          "IB-009",
          "IB-026",
          "IB-025",
          "IB-506",
          "IB-507",
          "IB-410",
          "IB-412",
          "IB-411",
          "IB-453",
          "IB-454",
          "IB-460",
          "IB-461",
          "IB-459",
          "IB-201",
          "IB-204",
          "IB-090",
          "IB-091",
          "IB-092",
          "IB-008",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.event = data.list;
    },
    async getVenue() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "IB-001",
          "IB-002",
          "IB-003",
          "IB-004",
          "IB-005",
          "IB-999",
          "IB-070",
          "IB-071",
          "IB-072",
          "IB-073",
          "IB-074",
          "IB-503",
          "IB-060",
          "IB-061",
          "IB-062",
          "IB-301",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.venue = data.list;
    },
    async getLottery() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "IB-300",
          "IB-302",
          "IB-303",
          "IB-304",
          "IB-305",
          "IB-306",
          "IB-307",
          "IB-308",
          "IB-309",
          "IB-310",
          "IB-311",
          "IB-312",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.lottery = data.list;
    },
    async getCeremony() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "CB-002",
          "CB-003",
          "CB-004",
          "CB-112",
          "CB-110",
          "CB-108",
          "CB-109",
          "CB-111",
          "CB-015",
          "CB-019",
          "CB-031",
          "CB-032",
          "CB-033",
          "CB-104",
          "CB-105",
          "CB-106",
          "CB-107",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.ceremony = data.list;
    },

    async getFoldingscreen() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: ["CB-207"],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.foldingscreen = data.list;
    },

    async getRag() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: ["PK-101", "PK-001", "IB-041", "IB-007"],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.rag = data.list;
    },

    //
    async getPanel() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "IB-501",
          "IB-502",
          "IB-508",
          "IB-509",
          "IB-505",
          "CB-101",
          "CB-103",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.panel = data.list;
    },

    //
    async getSports() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "IB-351",
          "IB-354",
          "ZU-001",
          "WU-001",
          "IB-374",
          "IB-353",
          "IB-352",
          "IB-365",
          "IB-366",
          "IB-367",
          "IB-372",
          "IB-373",
          "IB-363",
          "IB-364",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.sports = data.list;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.kv {
  width: 100%;
  @include mixin.liquidSize(height, 193, 100);
  background: {
    image: url("../../assets/img-sports.jpg");
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}
.head {
  @include mixin.liquidSize(margin-bottom, 30, 45);
}

.desc {
  line-height: 2;
  @include mixin.liquidSize(font-size, 15, 26);
  @include mixin.liquidSize(margin-bottom, 70, 60);
}

//-シーン別に探す
#scene {
  @include mixin.liquidSize(padding-top, 120, 120);
  @include mixin.liquidSize(padding-bottom, 60, 50);
}
//取引の流れ
#flow {
  @include mixin.liquidSize(padding-top, 60, 50);
}
</style>
