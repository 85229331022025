<template lang="pug">

  main.user
    Header
    section.kv

    section.content
      .m-content
        .breadcrumb
          breadcrumb(
            h2txt="商品発表会"
            h2link=""
            h3txt=""
            h3link=""
          )

        section
          h2.head
            head2(ja="商品発表会" en="Presentation")
          p.desc
            |製品やサービスの魅力を伝える各種展示会ブースに最適なイベント備品を取り揃えております。

        itemList(
          ja="幕・横断幕"
          en="Curtain"
          keyword="curtain"
          :list="curtain"
        )

        itemList(
          ja="椅子"
          en="Chair"
          keyword="chair"
          :list="chair"
        )

        itemList(
          ja="テーブル"
          en="Table"
          keyword="table"
          :list="table"
        )

        itemList(
          ja="ステージ・スロープ"
          en="Stage / Slope"
          keyword="stage"
          :list="stage"
        )

        itemList(
          ja="イベント用品・その他"
          en="Event / Other"
          keyword="event"
          :list="event"
        )

        itemList(
          ja="敷物類シート関連"
          en="Rag"
          keyword="rag"
          :list="rag"
        )

        itemList(
          ja="パネル・パーテンション"
          en="Panel"
          keyword="panel"
          :list="panel"
        )


    section#scene
      .m-content
        h3.head
          head1(ja="シーン別に探す" en="Scene" type="h")
      scene

    section#flow
      flow
    contact
    Footer
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import flow from "@/components/user/common/flow.vue";
import contact from "@/components/user/common/contact.vue";
import scene from "@/components/user/common/scene.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import head2 from "@/components/common/head-2.vue";
import bnrs from "@/components/user/common/bnrs.vue";
import head1 from "@/components/common/head-1.vue";
import ApiClient from "@/lib/http.js";
import itemList from "@/components/user/scene/itemList.vue";
export default {
  name: "Festival",
  metaInfo() {
    return {
      title: "商品発表会を開催するならカリトクで備品レンタル",
      meta: [
        { name: "description", content: "カリトクはイベント運営のプロ集団「株式会社ノーレイジ」が運営する、イベント備品レンタルサービスです。大規模フェスやコンサート、大手企業の社内イベントや大規模会議など、これまで数々の催し事を運営してきた私たちだからこそできる、細かいアドバイスや提案が可能なカリトクは、従来の貸すだけのサービスではなく、お客様の催し事が成功することまで一緒にサポートさせていただくことで好評を得ております。" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    contact,
    Footer,
    breadcrumb,
    head2,
    bnrs,
    flow,
    scene,
    head1,
    itemList,
  },
  data: function () {
    return {
      curtain: [],
      chair: [],
      table: [],
      summer: [],
      winter: [],
      stage: [],
      light: [],
      event: [],
      venue: [],
      lottery: [],
      rag: [],
      panel: [],
    };
  },
  computed: {
    loadImg(path) {
      return require("@/assets/" + path);
    },
  },
  async created() {
    this.getCurtain();
    this.getChair();
    this.getTable();
    //this.getSummer();
    //this.getWinter();
    this.getStage();
    //this.getLight();
    this.getEvent();
    //this.getVenue();
    //this.getLottery();
    this.getRag();
    this.getPanel();
  },
  methods: {
    async getCurtain() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "FW-010",
          "FW-020",
          "FW-030",
          "FW-050",
          "MA-630",
          "MA-650",
          "MK-630",
          "MK-650",
          "MK-750",
          "FC-001",
          "FC-030",
          "FC-050",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.curtain = data.list;
    },
    async getChair() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "CP-001",
          "CP-002",
          "CP-004",
          "CP-005",
          "CP-006",
          "CP-007",
          "CP-008",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.chair = data.list;
    },
    async getTable() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "TB-002",
          "TB-003",
          "TB-004",
          "TB-005",
          "TB-010",
          "TB-011",
          "TB-201",
          "TB-301",
          "TB-302",
          "TK-001",
          "TK-002",
          "TK-003",
          "TK-004",
          "TK-101",
          "TB-057",
          "TB-058",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.table = data.list;
    },
    async getSummer() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.summer = data.list;
    },
    async getWinter() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.winter = data.list;
    },
    async getStage() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "ST-102",
          "ST-104",
          "ST-091",
          "ST-093",
          "ST-094",
          "ST-001",
          "ST-002",
          "ST-003",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.stage = data.list;
    },
    async getLight() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.light = data.list;
    },
    async getEvent() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "CB-020",
          "CB-021",
          "CB-025",
          "CB-026",
          "CB-052",
          "CB-053",
          "CB-051",
          "AT-007",
          "AT-008",
          "AT-009",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.event = data.list;
    },
    async getVenue() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.venue = data.list;
    },
    async getLottery() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.lottery = data.list;
    },
    async getRag() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: ["PK-101", "PK-001", "IB-041", "IB-007"],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.rag = data.list;
    },
    async getPanel() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "IB-501",
          "IB-502",
          "IB-508",
          "IB-509",
          "IB-505",
          "CB-101",
          "CB-103",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.panel = data.list;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.kv {
  width: 100%;
  @include mixin.liquidSize(height, 193, 100);
  background: {
    image: url("../../assets/img-presentation.jpg");
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}
.head {
  @include mixin.liquidSize(margin-bottom, 30, 45);
}

.desc {
  line-height: 2;
  @include mixin.liquidSize(font-size, 15, 26);
  @include mixin.liquidSize(margin-bottom, 70, 60);
}

//-シーン別に探す
#scene {
  @include mixin.liquidSize(padding-top, 120, 120);
  @include mixin.liquidSize(padding-bottom, 60, 50);
}
//取引の流れ
#flow {
  @include mixin.liquidSize(padding-top, 60, 50);
}
</style>
