<!-- 日英 横並び -->
<template lang="pug">
  router-link.category-list__item(:to="'/category/' + url")
    span.icon
      img(:src="icon")

    span.ja {{ ja }}
    span.en {{ en }}
</template>

<script>
export default {
  name: "categoryItem",
  props: {
    icon: String,
    ja: String,
    en: String,
    url: String,
  },
  // computed: {
  //   loadImg() {
  //     return require("@/assets/" + this.icon);
  //   },
  // },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.category-list__item {
  @include mixin.liquidSize(min-width, 132, 150);
  @include mixin.liquidSize(height, 150, 160);
  @include mixin.liquidSize(padding-top, 25, 20);
  @include mixin.liquidSize(padding-left, 25, 10);
  @include mixin.liquidSize(padding-right, 25, 10);
  background: #f9f9f9;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  color: #000;
  position: relative;
  @include media.max-screen(media.$breakpoint-sm) {
  }
  @include media.min-screen(media.$breakpoint-md) {
    transition: all 0.4s ease-in-out 0.1s;
  }
}

.icon {
  @include mixin.liquidSize(width, 45, 45);
  @include mixin.liquidSize(height, 48, 48);
  display: flex;
  justify-content: center;
  align-items: center;
  @include mixin.liquidSize(margin-bottom, 0, 10);
  img {
    object-fit: contain;
  }
}
.name {
}
.ja {
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 12, 20);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include mixin.liquidSize(height, 35, 40);
  @include media.max-screen(media.$breakpoint-sm) {
    transform: scale(0.8);
  }
}
.en {
  font-weight: 500; /* medium */
  color: #848484;
  @include mixin.liquidSize(font-size, 12, 20);
  @include media.max-screen(media.$breakpoint-sm) {
    transform: scale(0.65);
  }
}
</style>
