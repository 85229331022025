<template lang="pug">
  section

    h3.scene-head
      span.ja {{ ja }}
      span.en {{ en}}

    ul.list
      li.list-item(v-for="product in list" :key="keyword + product.id")
        router-link(:to="'/product/' + product.id")
          .img(:style="{backgroundImage: 'url(\"' + product.img + '\")'}")
            //-img(:src="product.img")
          span.name {{ product.name }}
          .price
            span.num {{ (Number(product.price) + Number(product.after_second_day * product.price / 100)).toLocaleString() }}
            span.currency 円(税込)
              span(v-if="product.multiplePrice") 〜

</template>

<script>
export default {
  name: "itemList",
  props: {
    list: [],
    ja: String,
    en: String,
    keyword: String,
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

.scene-head {
  border-bottom: solid 1px #9cced8;
  @include mixin.liquidSize(padding-bottom, 12, 20);
  @include mixin.liquidSize(margin-bottom, 45, 50);
  .ja {
    @include mixin.liquidSize(font-size, 17, 26);
    font-weight: 700; /* bold */
    @include mixin.liquidSize(margin-right, 16, 20);
  }
  .en {
    color: #848484;
    @include mixin.liquidSize(font-size, 15, 24);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.list-item {
  @include mixin.liquidSize(width, 307, 337);
  @include mixin.liquidSize(margin-bottom, 100, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    &:nth-of-type(odd) {
      margin-right: auto;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    &:not(:nth-of-type(4n)) {
      @include mixin.liquidSize(margin-right, 30, 0);
    }
    transition: all 0.4s ease-in-out;
    &:hover {
      opacity: 0.4;
    }
  }

  .img {
    @include mixin.liquidSize(margin-bottom, 10, 14);
    @include mixin.liquidSize(width, 307, 337);
    @include mixin.liquidSize(height, 193, 205);
    background: {
      size: contain;
      position: center;
      repeat: no-repeat;
    }
  }
  .name {
    color: #000;
    @include mixin.liquidSize(font-size, 17, 26);
    display: block;
    @include mixin.liquidSize(margin-bottom, 3, 6);
  }
  .price {
    color: #1890a7;
    .num {
      @include mixin.liquidSize(font-size, 16, 26);
      @include mixin.liquidSize(margin-right, 3, 6);
    }
    .currency {
      @include mixin.liquidSize(font-size, 13, 20);
    }
  }
}
</style>
