<template lang="pug">
  .home
    Header
    main.admin

      section#menu
        .m-content-2
          h3.head
            head3(ja="管理メニュー" en="Menu")
        .menu-blk
          .m-content-2
            .menu-list
              router-link.menu-item(to="/admin/estimate/")
                span.ja 見積情報
                span.en Estimate
              router-link.menu-item(to="/admin/item/")
                span.ja 商品情報
                span.en Item List
              router-link.menu-item(to="/admin/settings/")
                span.ja 設定
                span.en Settings
              router-link.menu-item(to="/admin/category/")
                span.ja カテゴリ情報
                span.en Category


      section#history
        .m-content-2
          .head-blk
            h3.head
              head3(ja="見積書発行履歴" en="History")
            router-link.head-link(to="/admin/estimate/")
              span.txt 見積情報一覧を見る
              span.icon
                img(src="@/assets/icon-arrow-2.svg")
        .m-content-2
          .list-header
            span.list-header__item.fixed 正式見積
            span.list-header__item.publishDate 発行日
            span.list-header__item.num 見積書No.
            span.list-header__item.client 顧客名
            span.list-header__item.eventTitle 催事タイトル
            span.list-header__item.price 金額
            span.list-header__item.send 見積送信
            span.list-header__item.preview 見る
            span.list-header__item.edit 編集

          .list.form-style-1
            .list-item(
              v-for="item,index in this.estimate" :key="index"
              :data-status="item.status"
              :data-admin_saved="item.admin_saved"
            )
              .fixed
                label
                  input(type="checkbox" v-model="item.status" readonly disabled)
                  span
                span.m-sm 正式見積
              .publishDate
                span.m-sm.label-sm 発行日：
                |{{ item.created }}
              .num
                span.m-sm.label-sm 見積書No.：
                |{{ item.estimate_no }}
              .client
                span.m-sm.label-sm 顧客名：
                |{{ item.address }}
              .eventTitle
                span.m-sm.label-sm 催事タイトル：
                |{{ item.event_title }}
              .price {{ Number(item.fix_price).toLocaleString() }}
                span.currency 円(税込)

              .list-item__btns
                .send
                  router-link(:to="'/admin/estimate/confirm/' + item.id" v-if="item.admin_saved == '1'")
                    btn2(type="mail")
                router-link(:to="'/admin/estimate/detail/' + item.id")
                  btn2(type="preview")
                router-link(:to="'/admin/estimate/edit/' + item.id")
                  btn2(type="edit")


</template>

<script>
// @ is an alias to /src
import Header from "@/components/admin/common/header.vue";
import head3 from "@/components/common/head-3.vue";
import Footer from "@/components/user/common/footer.vue";
import btn2 from "@/components/admin/common/btn-2.vue";
import ApiClient from "@/lib/http.js";
export default {
  name: "Home",
  metaInfo() {
    return {
      title: "管理画面トップ | 関西でイベント備品のレンタルならカリトク",
      meta: [
        { name: "description", content: "カリトクはイベント運営のプロ集団「株式会社ノーレイジ」が運営する、イベント備品レンタルサービスです。大規模フェスやコンサート、大手企業の社内イベントや大規模会議など、これまで数々の催し事を運営してきた私たちだからこそできる、細かいアドバイスや提案が可能なカリトクは、従来の貸すだけのサービスではなく、お客様の催し事が成功することまで一緒にサポートさせていただくことで好評を得ております。" },
        { name: "keywords", content: "" },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  components: {
    Header,
    Footer,
    head3,
    btn2,
  },
  data: function () {
    return {
      estimate: [],
    };
  },
  async created() {
    this.getEstimate();
  },
  methods: {
    async getEstimate() {
      const api = new ApiClient();
      let data = await api.get("/admin/estimate/list");
      this.estimate = data.list;
    },
  },
};
</script>
<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

#menu {
  @include mixin.liquidSize(padding-top, 65, 0);
  @include mixin.liquidSize(padding-bottom, 100, 120);

  .head {
    @include mixin.liquidSize(margin-bottom, 50, 60);
    position: relative;
  }
}

.menu-blk {
  position: relative;
  &:before {
    content: "";
    background: #f7f5f2;
    position: absolute;
    right: 0;
    top: 0;
    @include media.max-screen(media.$breakpoint-sm) {
      width: calc(50% + 263 / 750 * 100vw);
      height: calc(100% + 20 / 750 * 100vw);
      top: calc(32 / 750 * 100vw);
    }
    @include media.min-screen(media.$breakpoint-md) {
      width: calc(50% + 572px);
      height: calc(100% + 18px);
      top: 28px;
    }
  }
}

.menu-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  position: relative;
}

.menu-item {
  @include mixin.liquidSize(width, 233, 700);
  @include mixin.liquidSize(height, 106, 120);
  background: #fff;
  display: flex;

  box-sizing: border-box;
  border: solid 1px #016f88;
  border-radius: 8px;

  @include mixin.liquidSize(padding-left, 30, 50);
  @include mixin.liquidSize(margin-right, 25, 0);
  &:not(:last-of-type) {
    @include mixin.liquidSize(margin-bottom, 0, 30);
  }
  @include media.max-screen(media.$breakpoint-sm) {
    align-items: center;
  }
  @include media.min-screen(media.$breakpoint-md) {
    flex-direction: column;
    justify-content: center;
    transition: all 1s ease;
    .ja,
    .en {
      transition: all 1s ease;
    }
    &:hover {
      background: #016f88;
      .ja {
        color: #fff;
      }
      .en {
        color: #fff;
      }
    }
  }

  .ja {
    color: #016f88;
    @include mixin.liquidSize(font-size, 18, 30);
    font-weight: 700; /* bold */
    @include mixin.liquidSize(margin-right, 0, 30);
  }
  .en {
    color: #016f88;
    opacity: 0.45;
    @include mixin.liquidSize(font-size, 11, 22);
  }
}

#history {
  padding-top: 1px;
  .head-blk {
    display: flex;
  }
  .head-link {
    @include mixin.liquidSize(margin-top, 72, 112);
    @include mixin.liquidSize(margin-left, 30, 60);
    @include media.min-screen(media.$breakpoint-md) {
      transition: all 0.4s ease-in-out;
      &:hover {
        opacity: 0.6;
      }
    }
    .txt {
      color: #1890a7;
      @include mixin.liquidSize(font-size, 14, 22);
      border-bottom: dotted 1px #1890a7;
    }
    .icon {
      display: inline-block;
      @include mixin.liquidSize(width, 17, 34);
      @include mixin.liquidSize(height, 16, 32);
      @include mixin.liquidSize(margin-left, 6, 6);
      @include mixin.liquidSize(top, -2, -4);
      position: relative;
      @media screen and (min-width: 768px) and (max-width: 1400px) {
        top: calc(3 / 1440 * 100vw);
      }
    }
  }
}

.list-header {
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  box-shadow: 0 24px 29px rgba(0, 0, 0, 0.02);
  @include mixin.liquidSize(margin-bottom, 20, 20);
  @include media.max-screen(media.$breakpoint-sm) {
    display: none;
  }
  .send {
    @include mixin.liquidSize(width, 95, auto);
  }
  .preview {
    @include mixin.liquidSize(width, 95, auto);
  }
  .edit {
    @include mixin.liquidSize(width, 74, auto);
  }
}

.list-header,
.list-item {
  @include mixin.liquidSize(font-size, 16, 30);
  .label-sm {
    @include mixin.liquidSize(font-size, 20, 24);
    color: #868383;
  }
  //正式見積
  .fixed {
    @include mixin.liquidSize(width, 78, 250);
    @include mixin.liquidSize(margin-bottom, 0, 40);
    display: flex;
    label {
      margin-right: 0px;
    }
  }
  //発行日
  .publishDate {
    @include mixin.liquidSize(width, 150, 450);
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  //見積書No.
  .num {
    @include mixin.liquidSize(width, 130, 450);
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  //催事タイトル
  .eventTitle {
    @include mixin.liquidSize(width, 190, 450);
    @include mixin.liquidSize(margin-bottom, 0, 40);
  }
  //顧客名
  .client {
    @include mixin.liquidSize(width, 190, 450);
    @include mixin.liquidSize(margin-bottom, 0, 40);
  }
  //金額
  .price {
    //width: 232px;
    @include mixin.liquidSize(width, 173, 700);
    display: inline-block;
    box-sizing: border-box;
    padding-right: 1em;
  }
}

.list-header__item {
  color: #868383;
  @include mixin.liquidSize(font-size, 14, 22);
  letter-spacing: 0.02em;
  position: relative;
  &.publishDate,
  &.num,
  &.client {
    &:after {
      //content: "";
      display: inline-block;
      width: 13px;
      height: 7px;
      margin-left: 14px;
      background: {
        image: url(../../assets/icon-tri-1.svg);
        size: contain;
        position: center;
        repeat: no-repeat;
      }
    }
  }
}

.list {
  @include mixin.liquidSize(margin-bottom, 50, 100);
}

.list-item {
  @include mixin.liquidSize(height, 90, auto);
  border-bottom: dotted 1px #dedede;
  @include media.max-screen(media.$breakpoint-sm) {
    padding: 30px 0 10px;
  }
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
    align-items: center;
  }

  .price {
    @include mixin.liquidSize(font-size, 20, 36);
    letter-spacing: 0.02em;
    color: #1890a7;
    text-align: right;
    .currency {
      font-size: 14px;
      letter-spacing: 0.04em;
    }
  }
}

.list-item__btns {
  margin-left: auto;
  display: flex;
  align-items: center;
  @include mixin.liquidSize(width, 261, 600);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: flex-end;
    margin-top: 20px;
  }
  .send {
    @include mixin.liquidSize(width, 95, auto);
    min-height: 1px;
  }
  .mail,
  .preview {
    margin-right: 20px;
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      margin-right: calc(19 / 1440 * 100vw);
    }
  }
}
</style>
