<template lang="pug">
  .category
    main.user
      Header
      categorySlider
      .m-content
        breadcrumb(
          :h2txt="category.title"
          :h2link="/category/ + category.id"
          :h3txt="product.name"
          h3link=""
        )

      #product
        .m-content.gr-1.product-slide
          //-スライド
          #slide
            agile(ref="main" :options="options1" :speed="300" :as-nav-for="asNavFor1" class="main")
              .slide.slide--main(v-for="(slide, index) in product.images" :key="index" :class="`slide--${index}`" :style="{backgroundImage: 'url(\"' + slide + '\")'}")
              .slide.slide--main(v-if="product.images.length < 1")
                img(src="/img/no_image.jpg")

            agile(ref="thumbnails" :options="options2" :speed="300" :as-nav-for="asNavFor2" class="thumbnails" v-if="product.images.length > 0")
              .slide.slide--thumbnail(v-for="(slide, index) in product.images" :key="index" @click="$refs.thumbnails.goTo(index);" :class="`slide--${index}`")
                .inner(:style="{backgroundImage: 'url(\"' + slide + '\")'}")


        //-説明
        .gr-2
          .description(v-if="product.description")
            .product-ttl
              span.ja 説明
              span.en description
            .description-detail
              p.description-txt(v-if="product.description") {{product.description}}
              ul.description-list(v-if="product.description[0]")
                li(v-for="(txt, index) in this.product.descriptions" :key="txt.title")
                  span.name {{ txt.title }}
                  span.desc(v-html="txt.body")


        //- カート部分
        .gr-3
          cart(
            @add2cart="getAdd2cart"
            :ja="product.name"
            en=""
            :cartItem="product.details"
            :supportVal="product.support"
            :afterSecondDay="product.after_second_day"
          )


        //-アテンションと受付時間
        .gr-4
          .m-content.gr-4-blk
            .inner
              .attention(v-if="product.attention")
                .product-ttl
                  span.ja アテンション
                  span.en attention
                p.attention-desc {{ product.attention}}

              .hour
                .hour-time
                  span.name 受付時間
                  span.time 10:00 - 18:00
                .hour-day
                  span.name 月
                  span.status ○
                .hour-day
                  span.name 火
                  span.status ○
                .hour-day
                  span.name 水
                  span.status ○
                .hour-day
                  span.name 木
                  span.status ○
                .hour-day
                  span.name 金
                  span.status ○
                .hour-day
                  span.name 土
                  span.status ×
                .hour-day
                  span.name 日
                  span.status ×
                .hour-day
                  span.name 祝
                  span.status ×
              p.holiday {{holidayDescription}}
            .contact.m-md
              a(href="tel:06-6534-5963")
                img(src="@/assets/bnr-contact-1.jpg")

      //-関連商品
      .related
        .m-content
          .related-head
            head1(ja="関連商品" en="Related products" type="h")
          #relatedSlide
            agile(ref="relates" :options="option3" :speed="300" class="relates")
              .slide.slide--related(v-for="(slide, index) in product.connections" :key="index" @click="$refs.thumbnails.goTo(index);" :class="`slide--${index}`")
                a.inner(:href="'./' + slide.id")
                  .img(:style="{backgroundImage: 'url(\"' + slide.img + '\")'}")
                  span.name {{ slide.name }}
                  .price
                    span.num {{ (Number(slide.price) + Number(slide.price * slide.after_second_day / 100)).toLocaleString() }}
                    span.currency 円(税込)
                    span(v-if="slide.size_count > 1")  〜

      flow
      contact
      Footer
      transition(name="fade")
        overlay(
          v-if="overlayState == 'alert'"
          type="A"
          icon="exclamation.svg"
          ttl="ご確認下さい"
          :msg="msgTxt"
          additionalBtn=false
          additionalBtnLink="/cart/"
          additionalBtnTxt=""
          @overlayOff="overlayOff"
        )
        overlay(
          v-if="overlayState == 'added'"
          type="A"
          icon="exclamation.svg"
          ttl="ご確認下さい"
          msg="見積カートに追加しました。<br>（仮予約期間：予約日より３日間）"
          additionalBtn=true
          additionalBtnLink="/cart/"
          additionalBtnTxt="見積りカートを見る"
          @overlayOff="overlayOff"
        )
</template>

<script>
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import flow from "@/components/user/common/flow.vue";
import contact from "@/components/user/common/contact.vue";
import categorySlider from "@/components/user/common/categorySlider.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import head1 from "@/components/common/head-1.vue";
import cart from "@/components/user/product/cart.vue";
import overlay from "@/components/common/overlay.vue";
import ApiClient from "@/lib/http.js";
export default {
  name: "Product",
  metaInfo() {
    let catTtl = this.product.name;
    let catDesc = this.product.description;
    return {
      title: catTtl + " | 関西でイベント備品のレンタルならカリトク",
      meta: [
        { name: "description", content: catDesc },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    flow,
    contact,
    Footer,
    categorySlider,
    breadcrumb,
    head1,
    cart,
    overlay,
  },
  data: function () {
    return {
      overlayState: "",
      msgTxt: "",
      product: {},
      holidayDescription: "",
      category: {},
      asNavFor1: [],
      asNavFor2: [],

      options1: {
        autoplay: false,
        dots: false,
        fade: false,
        navButtons: false,
        autoplaySpeed: 8000,
        timing: "ease-in-out",
        slidesToShow: 1,
        infinite: false,
      },
      options2: {
        autoplay: false,
        centerMode: false,
        dots: false,
        navButtons: true,
        slidesToShow: 4,
        autoplaySpeed: 3000,
        infinite: false,
      },
      option3: {
        unagile: false,
        autoplay: false,
        centerMode: false,
        dots: false,
        fade: false,
        navButtons: true,
        autoplaySpeed: 8000,
        timing: "ease-in-out",
        slidesToShow: 1,
        infinite: false,
        responsive: [
          {
            breakpoint: 750,
            settings: {
              unagile: true,
            },
          },
        ],
      },
    };
  },
  mounted: function () {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },
  methods: {
    getAdd2cart(...args) {
      console.log("getAdd2cart", args);
      console.log(args);
      let totalAmount = 0;
      let alertFlg = false;
      this.msgTxt = "";
      for (let $i = 0; $i < args[0].length; $i++) {
        totalAmount = totalAmount + args[0][$i].amount;
      }

      if (totalAmount < 1) {
        //カートが空の時
        this.msgTxt += "商品を選択してください。<br>";
        alertFlg = true;
      }
      if (args[1] == true || args[1] == undefined || args[1] == "") {
        //組み立てサポート未選択の時
        this.msgTxt += "組み立てサポート希望の有無を選択してください。";
        alertFlg = true;
      }

      if (alertFlg == true) {
        this.overlayState = "alert";
      } else {
        let param = {
          details: args[0],
          support: JSON.parse(args[1]) ? 1 : 0,
        };
        this.addCart(param);
        this.overlayState = "added";
      }
    },
    //overlay からのイベント
    overlayOff: function () {
      this.overlayState = false;
    },
    async getItem() {
      if (this.$route.params.id > 0) {
        const api = new ApiClient();
        let data = await api.get(
          "/item/detail/" + this.$route.params.id,
          null,
          null,
          false
        );
        this.product = data.detail;
        console.log("product", this.product)
        this.holidayDescription = data.holiday_description;

        data = await api.get(
          "/category/detail/" + this.product.category_id,
          null,
          null,
          false
        );
        this.category = data.detail;
      }
    },
    setH() {
      let origin = document.getElementsByClassName("cart");
      let originH = origin[0].offsetHeight;
      let target = document.getElementsByClassName("product-slide");
      console.log(target);
      console.log({ originH });
      //vw768以上の場合は高さを固定
      if (window.innerWidth > 768) {
        target[0].style.height = originH + "px";
      } else {
        target[0].style.height = "auto";
      }
      //リサイズ時にも高さを固定
      window.addEventListener("resize", () => {
        if (window.innerWidth > 768) {
          target[0].style.height = originH + "px";
        } else {
          target[0].style.height = "auto";
        }
      });
    },
    async addCart(param) {
      const api = new ApiClient();
      await api.post("/cart/add", param, false);
    },
  },
  async created() {
    this.getItem();
  },
  updated() {
    this.setH();
  },
};
</script>

<style lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
#slide,
#relatedSlide {
  .agile__list {
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  .agile__nav-button {
    position: absolute;
    top: 50%;
    @include mixin.liquidSize(width, 36, 40);
    @include mixin.liquidSize(height, 96, 102);
    background-repeat: no-repeat;
    background-size: 10px auto;
    text-indent: -10000px;
  }
  .agile__nav-button--prev {
    left: 0;
    background: {
      image: url(../assets/slide-left.svg);
      position: left center;
    }
    transform: translate(-100%, -50%);
  }
  .agile__nav-button--next {
    right: 0;
    background: {
      image: url(../assets/slide-right.svg);
      position: right center;
    }
    transform: translate(100%, -50%);
  }
}

#relatedSlide {
  .agile__slides {
    @include media.min-screen(media.$breakpoint-md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .agile__nav-button {
    @include mixin.liquidSize(top, 0, 132);
  }
}
</style>
<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

#product {
  //PC, SPで並びが違うのを制御
  position: relative;
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
    flex-direction: column;
    .gr-1 {
      order: 1;
    } //スライド
    .gr-2 {
      order: 3;
    } //説明
    .gr-3 {
      order: 4;
    } //カート
    .gr-4 {
      order: 2;
    } //アテンション
  }

  .gr-1 {
    @include mixin.liquidSize(min-height, 700, 0);
  }
}

//-スライド
#slide {
  @include mixin.liquidSize(width, 680, 700);
  @include mixin.liquidSize(margin-bottom, 60, 40);
}
.thumbnails {
  @include mixin.liquidSize(width, 608, 620);
  @include mixin.liquidSize(height, 96, 102);
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.slide,
.slide .inner {
  background: {
    size: contain;
    position: center;
    repeat: no-repeat;
  }
}
.slide--main {
  @include mixin.liquidSize(width, 680, 700);
  @include mixin.liquidSize(height, 430, 450);
  @include mixin.liquidSize(margin-bottom, 23, 23);
}
.slide--thumbnail {
  @include mixin.liquidSize(max-width, 152, 152);
  @include mixin.liquidSize(height, 96, 96);
  @include mixin.liquidSize(padding-left, 6, 6);
  @include mixin.liquidSize(padding-right, 6, 6);
  box-sizing: border-box;
  .inner {
    width: 100%;
    height: 100%;
  }
}

//-このページ共通見出し
.product-ttl {
  border-bottom: solid 1px #b2b1b1;
  @include mixin.lhFix(1);
  line-height: 1;
  @include mixin.liquidSize(padding-left, 0, 10);
  @include mixin.liquidSize(padding-right, 0, 10);
  @include mixin.liquidSize(padding-bottom, 15, 30);
  @include mixin.liquidSize(margin-bottom, 20, 40);
  display: flex;
  align-items: center;
  .ja {
    color: #1890a7;
    font-weight: 700; /* bold */
    @include mixin.liquidSize(font-size, 17, 32);
    @include mixin.liquidSize(margin-right, 15, 30);
  }
  .en {
    color: #848484;
    font-weight: 500; /* medium */
    @include mixin.liquidSize(font-size, 14, 26);
  }
}
//- 説明
.description {
  @include mixin.liquidSize(width, 1020, 750);
  background: #f7f9f9;
  margin-left: auto;
  margin-right: auto;
  @include mixin.liquidSize(border-radius, 13, 0);
  box-sizing: border-box;
  @include mixin.liquidSize(padding-top, 50, 35);
  @include mixin.liquidSize(padding-bottom, 50, 35);
  @include mixin.liquidSize(padding-left, 78, 25);
  @include mixin.liquidSize(padding-right, 78, 25);
  @include mixin.liquidSize(margin-bottom, 60, 60);
}

.description-txt {
  @include mixin.liquidSize(font-size, 15, 30);
  @include mixin.liquidSize(margin-bottom, 50, 30);
}

.description-list {
  @include mixin.liquidSize(line-height, 27, 50);
  li {
    &:not(:last-of-type) {
      @include mixin.liquidSize(margin-bottom, 20, 40);
    }
  }
  .name {
    @include mixin.liquidSize(font-size, 15, 30);
    display: block;
    font-weight: 700; /* bold */
  }
  .desc {
    @include mixin.liquidSize(font-size, 13, 26);
    display: block;
  }
}
//- アテンション
.gr-4 {
  @include mixin.liquidSize(margin-bottom, 60, 60);
  .inner {
    @include mixin.liquidSize(width, 680, 700);
  }
}

.gr-4-blk {
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
  }
}

.attention {
  @include mixin.liquidSize(margin-bottom, 30, 30);
}

.attention-desc {
  @include mixin.liquidSize(font-size, 15, 28);
  @include mixin.liquidSize(line-height, 28, 56);
}

.contact {
  @include mixin.liquidSize(width, 572, 0);
  margin: auto 0 0 auto;
  position: relative;
  @include media.min-screen(media.$breakpoint-md) {
    transition: all 0.4s ease-in-out;
    &:hover {
      opacity: 0.6;
    }
  }
}
//-受付時間
.hour {
  @include mixin.liquidSize(padding-top, 30, 40);
  border-top: solid 1px #c6c6c6;
  display: flex;
  @include mixin.liquidSize(margin-bottom, 20, 35);
}
.hour-time {
  @include mixin.liquidSize(width, 130, 180);
  box-sizing: border-box;
  border-right: solid 1px #c7c7c7;
  color: #575757;
  font-weight: 500; /* medium */
  @include mixin.liquidSize(padding-left, 0, 13);
  .name {
    @include mixin.liquidSize(font-size, 16, 20);
    display: block;
  }
  .time {
    @include mixin.liquidSize(font-size, 17, 22);
    display: block;
  }
}
.hour-day {
  @include mixin.liquidSize(width, 56, 76);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: solid 1px #c6c6c6;
  @include mixin.liquidSize(font-size, 17, 22);
  color: #575757;
  @include media.max-screen(media.$breakpoint-sm) {
    &:last-of-type {
      border-right: none;
    }
  }
  .name {
    line-height: 1;
    @include mixin.liquidSize(margin-bottom, 10, 20);
    @include mixin.lhFix(1);
  }
  .status {
    line-height: 1;
    @include mixin.lhFix(1);
  }
}
.holiday {
  color: #1890a7;
  @include mixin.liquidSize(font-size, 14, 26);
  @include mixin.liquidSize(line-height, 24, 46);
}

//-関連商品
.related {
  background: #fcfcfc;
  @include mixin.liquidSize(padding-top, 120, 90);
  @include mixin.liquidSize(padding-bottom, 80, 110);
}
.related-head {
  @include mixin.liquidSize(margin-bottom, 45, 80);
}
.relates {
  @include mixin.liquidSize(width, 1320, 620);
  @include mixin.liquidSize(height, auto, auto);
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.slide--related {
  @include mixin.liquidSize(max-width, 240, 620);
  @include mixin.liquidSize(min-width, 240, 620);
  @include mixin.liquidSize(padding-left, 0, 6);
  @include mixin.liquidSize(padding-right, 0, 6);
  @include mixin.liquidSize(margin-bottom, 30, 0);
  box-sizing: border-box;

  &:not(:nth-of-type(5n)) {
    @include mixin.liquidSize(margin-right, 30, 0);
  }
  .inner {
    width: 100%;
    height: 100%;
    display: block;
    @include media.min-screen(media.$breakpoint-md) {
      transition: all 0.4s ease-in-out;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .img {
    @include mixin.liquidSize(height, 152, 392);
    @include mixin.liquidSize(margin-bottom, 10, 14);
    background: {
      size: contain;
      position: center;
      repeat: no-repeat;
    }
  }
  .name {
    color: #000;
    @include mixin.liquidSize(font-size, 17, 26);
    display: block;
    @include mixin.liquidSize(margin-bottom, 3, 6);
  }
  .price {
    color: #1890a7;
    .num {
      @include mixin.liquidSize(font-size, 16, 26);
      @include mixin.liquidSize(margin-right, 3, 6);
    }
    .currency {
      @include mixin.liquidSize(font-size, 13, 20);
    }
  }
}
</style>
