<template lang="pug">
  section.category
    transition(name="fade")
      .inProgress(v-if="inProgress")
        .loader
          .loader-inner.ball-clip-rotate
            div
    Header(seeing="category")
    main.admin
      .m-content-2
        breadcrumb(
          h2txt="カテゴリ情報"
          h2link="/admin/category/"
          h3txt="カテゴリ追加"
          h3link=""
          type="admin"
        )
        head3(ja="カテゴリ追加" en="Add Category")


        section.form
          .form-style-1
            .row-1
              .ja
                span.label カテゴリ名
                input.name(type="text" v-model="item.title")
                .error {{ errors.title ? errors.title[0] : '' }}
              .en
                span.label 英語表記
                input.name(type="text" v-model="item.sub_title")
                .error {{ errors.sub_title ? errors.sub_title[0] : '' }}
            .row-2
              .desc-blk
                span.label 説明文
                textarea.desc(v-model="item.description")
                .error {{ errors.description ? errors.description[0] : '' }}
            .row-3
              span.label メインビジュアル画像登録
              .kv-blk
                input#kv-input.kv(type="file" ref="kvImage" @change="kvChange")
                label.img-input(for="kv-input") メインビジュアル画像

                .img-preview(data-input="kv-input")
                  img(:src="kvPreview")

            .row-4
              span.label アイコン画像登録
              .icon-blk
                input#icon-input(type="file" ref="iconImage" @change="iconChange")
                label.img-input(for="icon-input") アイコン画像

                .img-preview(data-input="icon-input")
                  img(:src="iconPreview")

          .btns
            btn(
              btnTxt="戻る"
              color="white"
              size="s"
              @click="back"
            )

            btn(
              btnTxt="このカテゴリを追加"
              color="primary"
              size="l"
              @click="addCategory"
            )





</template>

<script>
import Header from "@/components/admin/common/header.vue";
import head3 from "@/components/common/head-3.vue";
import btn from "@/components/admin/common/btn-1.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import { common } from "@/mixins/common.js";
import ApiClient from "@/lib/http.js";
export default {
  name: "Category",
  metaInfo() {
    return {
      title: "カテゴリ追加 | 関西でイベント備品のレンタルならカリトク",
      meta: [
        { name: "description", content: "カリトクはイベント運営のプロ集団「株式会社ノーレイジ」が運営する、イベント備品レンタルサービスです。大規模フェスやコンサート、大手企業の社内イベントや大規模会議など、これまで数々の催し事を運営してきた私たちだからこそできる、細かいアドバイスや提案が可能なカリトクは、従来の貸すだけのサービスではなく、お客様の催し事が成功することまで一緒にサポートさせていただくことで好評を得ております。" },
        { name: "keywords", content: "" },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  components: {
    Header,
    head3,
    btn,
    breadcrumb,
  },
  mixins: [common],
  data: function () {
    return {
      kvPreview: "",
      iconPreview: "",
      item: {},
      errors: {},
      inProgress: false,
    };
  },
  methods: {
    kvChange: function () {
      let file = this.$refs.kvImage.files[0];
      this.kvPreview = URL.createObjectURL(file);
      this.item.img_name = file.name;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.item.img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    iconChange: function () {
      let file = this.$refs.iconImage.files[0];
      this.iconPreview = URL.createObjectURL(file);
      this.item.icon_name = file.name;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.item.icon = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async addCategory() {
      this.inProgress = true;
      const api = new ApiClient();
      let data = await api.post("/admin/category/save", this.item);
      console.log("addCategory");
      console.log(data);
      if (data.state == 204) {
        this.errors = data.errors;
        this.windowTop();
        this.inProgress = false;
        return;
      }
      this.$router.push("/admin/category");
      this.inProgress = false;
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.head-blk {
  @include mixin.liquidSize(margin-bottom, 65, 40);
}

.list-header {
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  box-shadow: 0 24px 29px rgba(0, 0, 0, 0.02);
  @include mixin.liquidSize(margin-bottom, 20, 20);
}

.list-header__item {
  color: #868383;
  @include mixin.liquidSize(font-size, 14, 22);
  letter-spacing: 0.02em;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: -14px;
    top: 50%;
    transform: translate(100%, -50%);
    width: 13px;
    height: 7px;
    background: {
      image: url(../../../assets/icon-tri-1.svg);
      size: contain;
      position: center;
      repeat: no-repeat;
    }
  }
}

.btns {
  display: flex;
  width: 100%;
  @include mixin.liquidSize(margin-bottom, 30, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: flex-end;
  }
  .button {
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px 20px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      margin: 0 0 0 20px;
    }
  }
}

.note {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 32px;
  box-sizing: border-box;
  @include mixin.liquidSize(font-size, 13, 22);
  line-height: 1.75;
  @include media.min-screen(media.$breakpoint-md) {
    width: 535px;
    margin-left: auto;
  }
  .icon {
    width: 22px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.form {
  .label {
    @include mixin.liquidSize(font-size, 17, 22);
    letter-spacing: 0.04em;
    display: block;
    @include mixin.liquidSize(margin-bottom, 17, 20);
  }
  .form-style-1 {
    @include mixin.liquidSize(margin-bottom, 40, 60);
  }
  .img-input,
  .img-preview {
    @include mixin.liquidSize(width, 190, 250);
    @include mixin.liquidSize(height, 190, 250);
    box-sizing: border-box;
    border: dashed 1px #b5b5b5;
  }
  .img-input {
    display: block;
    background: {
      image: url(../../../assets/icon-plus-1.svg);
      repeat: no-repeat;
      position: top 56px center;
    }
    text-align: center;
    @include mixin.liquidSize(font-size, 14, 22);
    color: #767676;
    padding: 15px;
    position: relative;
    @include mixin.liquidSize(padding-top, 115, 115);
    @include mixin.liquidSize(margin-right, 34, 40);
    @include media.max-screen(media.$breakpoint-sm) {
      background-position: center center;
      text-indent: -10000px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      background-position: top 56px center;
      &:before {
        content: '';
        opacity: 0;
        background: rgba(0, 0, 0, 0.05);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: all .4s ease-in-out;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      background-position: top calc(56 / 1440 * 80vw) center;
      background-size: 3vw auto;
      padding-top: calc(115 / 1440 * 100vw);
    }
  }
  .img-preview {
    display: flex;
    align-items: center;
  }
  textarea.desc {
    width: 100%;
    height: 130px;
  }
  //1行目
  .row-1 {
    padding-left: 40px;
    padding-right: 30px;
    padding-bottom: 25px;
    border-bottom: dotted 1px #dedede;
    @include media.min-screen(media.$breakpoint-md) {
      display: flex;
    }
    .ja,
    .en {
      @include media.max-screen(media.$breakpoint-sm) {
        margin-bottom: 20px;
      }
      @include media.min-screen(media.$breakpoint-md) {
        margin-right: 30px;
      }
    }
    .ja {
      position: relative;
      &:before {
        content: "";
        width: 3px;
        height: 45px;
        background: #12aec0;
        position: absolute;
        left: -40px;
        bottom: 0;
      }
    }
    .en {
      @include media.max-screen(media.$breakpoint-sm) {
        position: relative;
        &:before {
          content: "";
          width: 3px;
          height: 45px;
          background: #12aec0;
          position: absolute;
          left: -40px;
          bottom: 0;
        }
      }
    }
    .name {
      @include media.max-screen(media.$breakpoint-sm) {
        width: 100%;
      }
      @include media.min-screen(media.$breakpoint-md) {
        width: 345px;
      }
    }
  }
  //2行目
  .row-2 {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 40px;
    padding-right: 30px;
    border-bottom: dotted 1px #dedede;
  }
  //3行目
  .row-3 {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 40px;
    border-bottom: dotted 1px #dedede;

    .kv-blk {
      display: flex;
    }
  }
  //4行目
  .row-4 {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 40px;
    border-bottom: dotted 1px #dedede;

    .icon-blk {
      display: flex;
    }
  }
}
</style>
