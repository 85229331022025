<template lang="pug">
  section.category
    Header(seeing="category")
    main.admin
      .m-content-2
        breadcrumb(
          h2txt="カテゴリ情報"
          h2link=""
          h3txt=""
          h3link=""
          type="admin"
        )
        head3(ja="カテゴリ情報" en="Category List")

        .list-header
          span.list-header__item カテゴリ名

        .list
          .list-item(v-for="item,index in this.category" :key="item[index]")
            span.name {{ item.title }}
            .list-item__btns
              .list-item__sort
                span.up(:class="{top: index == 0}" @click="up(index, item)")
                  img(src="@/assets/icon-sort-1-up.svg")
                .separator
                span.down(:class="{bottom: category.length == (index + 1)}" @click="down(index, item)")
                  img(src="@/assets/icon-sort-1-down.svg")
              //-.delete(v-if="item.title != 'セット商品'" @click="deleteCategory(item.id)") 削除
              .delete(v-if="item.title != 'セット商品'" @click="overlayState = 'deleteItem', itemName = item.title, itemId = item.id") 削除
              router-link(v-if="item.title != 'セット商品'" :to="'/admin/category/edit/' + item.id")
                btn2(type="edit")


        .btns
          btn(
            btnTxt="戻る"
            color="white"
            size="s"
            @click="back"
          )
          btn(
            btnTxt="保存する"
            color="white"
            size="m"
            @click="saveSort"
          )
          router-link(to="/admin/category/add/")
            btn(
              btnTxt="カテゴリを追加する"
              color="primary"
              size="l"
            )

        p.note
          span.icon
            img(src="@/assets/icon-q.svg")
          |並び順を変更した場合は、「保存する」ボタンを押すことで反映されます。

      transition(name="fade")
        overlay(
          v-if="overlayState == 'deleteItem'"
          type="deleteItem"
          icon="exclamation.svg"
          ttl="ご確認下さい"
          :msg="itemName + 'を削除しようとしています。<br>この操作は取り消せません。<br>本当に削除しますか？'"
          additionalBtn=false
          additionalBtnLink="/cart/"
          additionalBtnTxt=""
          @overlayOff="overlayOff"
          @deleteSubmit="deleteSubmit"
        )
</template>

<script>
import Header from "@/components/admin/common/header.vue";
import head3 from "@/components/common/head-3.vue";
import btn from "@/components/admin/common/btn-1.vue";
import btn2 from "@/components/admin/common/btn-2.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import { common } from "@/mixins/common.js";
import ApiClient from "@/lib/http.js";
import overlay from "@/components/common/overlay.vue";
export default {
  name: "Category",
  metaInfo() {
    return {
      title: "カテゴリ一覧 | 関西でイベント備品のレンタルならカリトク",
      meta: [
        { name: "description", content: "カリトクはイベント運営のプロ集団「株式会社ノーレイジ」が運営する、イベント備品レンタルサービスです。大規模フェスやコンサート、大手企業の社内イベントや大規模会議など、これまで数々の催し事を運営してきた私たちだからこそできる、細かいアドバイスや提案が可能なカリトクは、従来の貸すだけのサービスではなく、お客様の催し事が成功することまで一緒にサポートさせていただくことで好評を得ております。" },
        { name: "keywords", content: "" },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  components: {
    Header,
    head3,
    btn,
    btn2,
    breadcrumb,
    overlay,
  },
  mixins: [common],
  data: function () {
    return {
      category: [],
      overlayState: "",
      itemName: "",
      itemId: "",
    };
  },
  async created() {
    this.getCategory();
  },
  methods: {
    //overlay からのイベント
    ////モーダル解除
    overlayOff: function () {
      this.overlayState = false;
    },
    deleteSubmit: function () {
      this.deleteCategory(this.itemId);
    },
    async getCategory() {
      const api = new ApiClient();
      let data = await api.get("/admin/category");
      this.category = data.list;
    },
    async deleteCategory(id) {
      let param = {
        id: id,
      };
      const api = new ApiClient();
      await api.post("/admin/category/delete", param);
      this.getCategory();
    },
    up(index, row) {
      let preIndex = index - 1;
      let clickSort = this.category[index].sort;
      this.category[index].sort = this.category[preIndex].sort;
      this.category[preIndex].sort = clickSort;

      this.category.splice(index, 1, this.category[preIndex]);
      this.category.splice(preIndex, 1, row);
    },
    down(index, row) {
      let nextIndex = index + 1;
      let clickSort = this.category[index].sort;
      this.category[index].sort = this.category[nextIndex].sort;
      this.category[nextIndex].sort = clickSort;

      this.category.splice(index, 1, this.category[nextIndex]);
      this.category.splice(nextIndex, 1, row);
    },
    async saveSort() {
      let param = {
        sort: this.category,
      };
      const api = new ApiClient();
      await api.post("/admin/category/sort", param);
      this.windowTop();
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.head-blk {
  @include mixin.liquidSize(margin-bottom, 65, 40);
}

.list-header {
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  box-shadow: 0 24px 29px rgba(0, 0, 0, 0.02);
  @include mixin.liquidSize(margin-bottom, 20, 20);
}

.list-header__item {
  color: #868383;
  font-size: 14px;
  letter-spacing: 0.02em;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: -14px;
    top: 50%;
    transform: translate(100%, -50%);
    width: 13px;
    height: 7px;
    background: {
      image: url(../../../assets/icon-tri-1.svg);
      size: contain;
      position: center;
      repeat: no-repeat;
    }
  }
}

.list {
  @include mixin.liquidSize(margin-bottom, 50, 100);
}

.list-item {
  @include mixin.liquidSize(height, 90, auto);
  border-bottom: dotted 1px #dedede;
  @include media.max-screen(media.$breakpoint-sm) {
    padding: 30px 0 10px;
  }
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
    align-items: center;
  }
  .name {
    color: #4e4e4e;
    font-size: 20px;
    font-weight: 700; /* bold */
    letter-spacing: 0.04em;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    @include mixin.liquidSize(font-size, 20, 36);
    @include mixin.liquidSize(padding-left, 34, 50);
    @include mixin.liquidSize(margin-bottom, 0, 20);
    &:before {
      content: "";
      width: 3px;
      height: 45px;
      background: #12aec0;
      position: absolute;
      left: 0;
      top: -8px;
    }
  }
}

.list-item__btns {
  margin-left: auto;
  display: flex;
  align-items: center;
  @include mixin.liquidSize(width, 220, 600);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: flex-end;
    margin-top: 20px;
  }
  .delete {
    margin: 0 34px;
    color: #44b9d0;
    letter-spacing: 0.04em;
    @include mixin.liquidSize(font-size, 15, 30);
    @include mixin.liquidSize(margin-left, 34, 34);
    @include mixin.liquidSize(margin-right, 34, 34);
    cursor: pointer;
    @include media.min-screen(media.$breakpoint-md) {
      transition: all 0.4s ease-in-out;
      &:hover {
        opacity: 0.4;
      }
    }
  }
}

.list-item__sort {
  display: flex;
  align-items: center;
  @include mixin.liquidSize(width, 23, auto);
  @include media.min-screen(media.$breakpoint-md) {
    flex-direction: column;
    align-items: center;
  }
  .up,
  .down {
    @include mixin.liquidSize(width, 23, 46);
    @include mixin.liquidSize(height, 33, 66);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px;
      padding: 5px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      transition: all 0.4s ease-in-out;
      &:hover {
        opacity: 0.4;
      }
    }
    &.top,
    &.bottom {
      pointer-events: none;
      opacity: 0.2;
    }
  }
  .separator {
    width: 100%;
    height: 0;
    border-bottom: dotted 1px #12aec0;
    opacity: 0.38;
    @include media.max-screen(media.$breakpoint-sm) {
      display: none;
    }
  }
}

.btns {
  display: flex;
  width: 100%;
  @include mixin.liquidSize(margin-bottom, 30, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: flex-end;
  }
  .button {
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px 20px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      margin: 0 0 0 20px;
    }
  }
}

.note {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 32px;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 1.75;
  @include media.min-screen(media.$breakpoint-md) {
    width: 535px;
    margin-left: auto;
  }
  .icon {
    width: 22px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
