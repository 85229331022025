<template lang="pug">
  ul.cart-list
    li(v-for="item, index in this.items" :key="`item${index}`")
      .img(:style="{backgroundImage: 'url(' + item.img + ')'}")
        //-img(:src="item.img")
      .gr-1
        .inner
          .name {{ item.name }}
          span.type {{item.size}}
        span.price {{ (Number(item.price) + Number(Math.floor(item.after_second_day_price))).toLocaleString() }}
          span.currency 円(税込)
          br
          span.after 3日目以降
          br
          |+{{Math.floor(item.after_second_day_price)}}
          span.currency 円(税込) / 1日

      .gr-2
        .btn(:class="{nochange: nochange}")
          .minus(@click="minus(item)")
          //-.num {{ item.amount }}
          input.num(type="number" v-model="item.amount" min="0" @change="calcTotal")
          .plus(@click="plus(item)")
        //- ユーザー画面の時と admin/estimate/editの時で分岐させる（idのkeyが違う key, item_detail_id
        span.delete(@click="deleteItem(item.id)") 削除
        //-span.delete(v-if="type == 'admin'" @click="deleteItem(item.item_detail_id)") 削除
        //-{{item}}

      .support(v-if="item.item_support == 1" :class="{nochange: nochange}")
        label
          input(type="checkbox" class="suport-check" v-model="item.support" true-value="1" false-value="0" @change="changeSupport(item)")
          .support-toggle

</template>

<script>
export default {
  name: "cartItem",

  data: function () {
    return {
      name: "テント",
      amount: 0, //数
      total: 0, //この商品の小計
      after_second_day_price: 0, //二日以降の価格
      discount: 0, //法人 10%割引
    };
  },
  props: {
    items: [],
    nochange: Boolean,
    type: String,
  },
  methods: {
    plus: function (item) {
      item.amount++;
      this.$emit("update", item);
      this.calcTotal();
    },
    minus: function (item) {
      item.amount--;
      if (item.amount < 0) {
        item.amount = 0;
      }
      this.$emit("update", item);
      this.calcTotal();
    },
    calcTotal: function () {
      this.total = 0;
      this.after_second_day_price = 0;
      for (let i = 0; i < this.items.length; i++) {
        let o = this.items[i];
        this.total += o.price * o.amount;
        this.after_second_day_price +=
          Math.floor(o.after_second_day_price) * o.amount;
      }
      this.$emit(
        "changeTotal",
        this.total,
        this.items,
        this.after_second_day_price
      );
    },
    changeSupport: function (item) {
      this.$emit("update", item);
    },
    deleteItem: function (id) {
      this.$emit("deleteItem", id);
    },
  },
  watch: {
    items: function () {
      this.calcTotal();
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.cart-list {
  @include mixin.liquidSize(margin-bottom, 20, 20);
}
li {
  border-bottom: dotted 1px #dedede;
  @include mixin.liquidSize(padding-top, 13, 30);
  @include mixin.liquidSize(padding-bottom, 13, 30);
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.img {
  @include mixin.liquidSize(width, 140, 0);
  @include mixin.liquidSize(height, 88, 0);
  @include mixin.liquidSize(margin-right, 30, 0);
  overflow: hidden;
  background: {
    size: contain;
    position: center;
    repeat: no-repeat;
  }
}

//-
.gr-1 {
  @include mixin.liquidSize(width, 453, 316);
  box-sizing: border-box;
  @include mixin.liquidSize(padding-right, 30, 60);
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
  }
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mixin.liquidSize(width, 285, 316);
  }
}
.name {
  @include mixin.liquidSize(font-size, 18, 24);
}
.type {
  @include mixin.liquidSize(width, 310, 316);
  display: block;
  @include mixin.liquidSize(font-size, 16, 20);
  @include mixin.liquidSize(margin-right, 25, 0);
  margin-top: 0.35em;
}
.price {
  color: #1890a7;
  @include mixin.liquidSize(font-size, 16, 26);
  @include mixin.liquidSize(width, 150, 316);
  text-align: right;
  .currency {
    @include mixin.liquidSize(font-size, 15, 22);
  }
  .after {
    display: inline-block;
    @include mixin.liquidSize(margin-top, 10, 10);
    @include mixin.liquidSize(font-size, 13, 20);
  }
}

//-
.gr-2 {
  @include mixin.liquidSize(width, 144, 224);
  box-sizing: border-box;
  display: flex;

  @include media.max-screen(media.$breakpoint-sm) {
    align-items: center;
  }
  @include media.min-screen(media.$breakpoint-md) {
    flex-direction: column;
  }
}
.btn {
  display: flex;
  align-items: center;
  @include mixin.liquidSize(width, 144, 140);
  @include mixin.liquidSize(height, 44, 42);
  @include mixin.liquidSize(border-radius, 33, 33);
  border: solid 1px #848484;
  box-sizing: border-box;
  @include mixin.liquidSize(margin-top, auto, 0);
  overflow: hidden;
  &.nochange {
    pointer-events: none;
  }
  .num {
    box-sizing: border-box;
    border-left: dotted 1px #dedede;
    border-right: dotted 1px #dedede;
    @include mixin.liquidSize(font-size, 17, 26);
    color: #848484;
    @include mixin.liquidSize(width, 54, 54);
    @include mixin.liquidSize(height, 33, 42);
    //@include mixin.liquidSize(padding-right, 15, 18);
    @include mixin.liquidSize(padding-right, 5, 8);
    @include mixin.liquidSize(padding-top, 0, 0);
    @include mixin.liquidSize(padding-bottom, 0, 5);
    text-align: right;
  }
  .plus,
  .minus {
    @include mixin.liquidSize(width, 45, 43);
    height: 100%;
    position: relative;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      background: #707070;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.4s ease-in-out;
    }
    @include media.min-screen(media.$breakpoint-md) {
      transition: all 0.4s ease-in-out;
      &:hover {
        background: #efefef;
        &:before,
        &:after {
          background: #939393;
        }
      }
    }
  }
  .plus {
    &:before,
    &:after {
      left: 40%;
    }
    &:before {
      @include mixin.liquidSize(width, 16, 16);
      height: 1px;
    }
    &:after {
      @include mixin.liquidSize(height, 16, 16);
      width: 1px;
    }
  }
  .minus {
    &:before {
      left: 60%;
      @include mixin.liquidSize(width, 16, 16);
      height: 1px;
    }
  }
}

.delete {
  color: #1890a7;
  @include mixin.liquidSize(font-size, 16, 20);
  line-height: 1;
  @include mixin.liquidSize(margin-left, 0, 26);
  @include mixin.liquidSize(margin-top, 7, 0);
  @include mixin.liquidSize(margin-bottom, -30, 0);
  cursor: pointer;
  @include media.min-screen(media.$breakpoint-md) {
    width: 100%;
    text-align: center;
    display: block;
  }
}

//-
.support {
  @include mixin.liquidSize(width, 77, 80);
  display: flex;
  align-items: center;
  &.nochange {
    pointer-events: none;
  }
  label {
    @include mixin.liquidSize(height, 20, 30);
    margin-left: auto;
  }
}

.support-toggle {
  @include mixin.liquidSize(width, 35, 56);
  @include mixin.liquidSize(height, 20, 30);
  @include mixin.liquidSize(border-radius, 10, 15);
  background: #939393;
  position: relative;
  transition: all 0.3s ease-in-out 0.1s;
  cursor: pointer;
  &:before {
    content: "";
    @include mixin.liquidSize(width, 20, 30);
    @include mixin.liquidSize(height, 20, 30);
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease-in-out 0.1s;
  }
}

.suport-check {
  display: none;
  &:checked {
    + .support-toggle {
      background: #1890a7;
      &:before {
        @include mixin.liquidSize(left, 15, 26);
      }
    }
  }
}
</style>
