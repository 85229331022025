<template lang="pug">
  #cart
    #cart-product
      .cart-blk.cart-blk__product
        .cart-head
          span.name 製品名
          span.price.m-md 値段
          span.amount 個数
          span.support 組み立て
            br
            |サポート
            span.icon
              img(src="@/assets/icon-q.svg")
        cartItem(
          @changeTotal="total"
          @update="update"
          @deleteItem="deleteItem"
          :items="cartItem"
          :nochange="nochange"
          :type="type"
        )
        //-.error(v-if="error.carts") {{ error.carts[0]}}

        p.note
          span.icon
            img(src="@/assets/icon-q.svg")
          span.head 組み立てサポート：
          |組み立て・解体作業を弊社でサポートするサービスです。

      .cart-blk.cart-blk__period
        .period(:class="{nochange: nochange}")
          span.head レンタル期間：
          .gr.period-input(:class="{'has-value': model.start_date != ''}")
            datepicker(v-if="showPeriod" id="start_date" :language="ja" format="yyyy年MM月dd日(D)" v-model="start_date" @closed="pickerClosed('start')" @opened="pickerChange(index)" placeholder="カレンダーから選択")
            .delete-btn(@click="deletePeriodValue('start')")
              |×
            //-input(type="date" name="startDate" v-model="model.start_date" @change="calcPeriod")
            span.txt 開始
          .gr.period-input(:class="{'has-value': model.end_date != ''}")
            datepicker(v-if="showPeriod" id="end_date" :language="ja" format="yyyy年MM月dd日(D)" v-model="end_date" :value="end_date" @closed="pickerClosed('end')" @opened="pickerChange(index)" placeholder="カレンダーから選択")
            .delete-btn(@click="deletePeriodValue('end')")
              |×
            //-input(type="date" name="endDate" v-model="model.end_date" @change="calcPeriod")
            span.txt 終了
          //-.error(v-if="error.start_date") {{ error.start_date[0]}}
          //-.error(v-if="error.end_date") {{ error.end_date[0]}}
          //-.error(v-if="error.holiday") {{ error.holiday[0]}}
          //-.error(v-if="error.businessDay") {{ error.businessDay[0]}}
        p.holiday {{holidayDescription}}


    #cart-price
      .subtotal.item
        span.head レンタル日数
        span.num {{period.toLocaleString()}}
        span.currency 日
      //-.subtotal.item
        span.head 税別小計
        span.num {{ (subtotal + discount).toLocaleString()}}
        span.currency 円
      .subtotal.item(v-if="pick_up == 2 || give_back == 2")
        span.head 送料
        span.num {{ Number(actualSendPrice).toLocaleString() }} 
        span.currency 円
      .subtotal.item(v-if="support_price > 0")
        span.head 組み立てサポート
        span.num {{ Number(support_price).toLocaleString() }} 
        span.currency 円
      //- attribute == 1 = 法人の場合
      .subtotal.item(v-if="attribute == 1")
        span.head 税込合計
        //送料
        span.num {{ (fixPrice + discount + Number(actualSendPrice) + Number(support_price)).toLocaleString() }}
        span.currency 円
      .tax.item.discount(v-if="attribute == 1")
        span.head 法人割引適用額
        span.num -{{ discount.toLocaleString() }}
        span.currency 円
      //-.subtotal.item(v-if="attribute == 1")
        span.head 割引適用後小計
        span.num {{ subtotal.toLocaleString() }}
        span.currency 円
      //-.tax.item
        span.head 消費税（10%）
        span.num {{tax.toLocaleString()}}
        span.currency 円


      .total
        span.head 税込総計
        //送料
        span.num {{ (fixPrice + Number(actualSendPrice) + Number(support_price)).toLocaleString() }}
        span.currency 円
      .tax 内消費税/10%：{{(Math.ceil(fixPrice - fixPrice / 1.1)).toLocaleString()}} 円


</template>

<script>
import cartItem from "@/components/user/cart/cartItem.vue";
import ApiClient from "@/lib/http.js";
import datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import dayjs from "dayjs";
export default {
  name: "cart",
  components: {
    cartItem,
    datepicker
  },
  data: function () {
    return {
      subtotal: 0,
      tax: 0,
      totalPrice: 0, //cartItemからの合計金額（1日分の金額）
      after_second_day_price: 0, //3日以降の価格
      items: [],
      period: 0,
      fixPrice: 0, //レンタル期間を考慮した金額（totalPrice * period）
      error: {},
      discount: 0,
      holidayDescription: "",
      ja: ja,
      start_date: "",
      end_date: "",
      showPeriod: true,
      actualSendPrice: 0, //受け渡し方法の内容を考慮した送料
      //※開始・返却のどちらかが
      // 配送になっていれば送料を含める
      //「引き取り」「来社」は送料なし
    };
  },
  props: {
    cartItem: [],
    model: {},
    errors: {},
    attribute: String,
    nochange: Boolean,
    type: String,
    send_price: Number,
    support_price: Number,
    pick_up: Number,
    give_back: Number,
  },
  model: {
    prop: "model",
    event: "change",
  },
  async created() {
    await this.getHolidayDesc();
    this.start_date = this.model.start_date;
    this.end_date = this.model.end_date;
    console.log(this.model);
  },
  methods: {
    pickerClosed(type) {
      console.log("pickerClosed(" + type + ")")
      if(type == 'start') {
        this.start_date = dayjs(this.start_date).format("YYYY-MM-DD");
        this.model.start_date = this.start_date;
      } else {
        this.end_date = dayjs(this.end_date).format("YYYY-MM-DD");
        this.model.end_date = this.end_date;
      }
      //this.calcPeriod(type);
    },
    deletePeriodValue: function (type) {
      if(type == 'start') {
        this.start_date = '';
        this.model.start_date = '';
      } else {
        this.end_date = '';
        this.model.end_date = '';
      }
      //this.calcPeriod(type);
    },
    async getHolidayDesc() {
      //休日の本文を取得
      const api = new ApiClient();
      let data2 = await api.get(
        "/item/detail/3",
        null,
        null
      );
      this.holidayDescription = data2.holiday_description;
    },
    total(...args) {
      this.totalPrice = args[0];
      this.items = args[1];
      this.after_second_day_price = args[2];
      this.calcFixPrice();
    },
    calcPeriod() {
      //console.log("calcPeriod");
      this.toggleShowPeriod();
      let st = new Date(this.start_date);
      let en = new Date(this.end_date);
      let today = new Date();
      let period = (en - st) / 86400000 + 1;
      let leftDays = (st - today) / 86400000;
      console.log("period:" + Number(period));
      this.period = Number(period);
      //console.log(e);
      if (period <= 0) {
        //console.log("0日かマイナス");
        //console.log(e);
        //e.target.value = "";
        //this.model.end_date = '';
        //this.error.end_date = "終了日は開始日以降に設定してくださいよ";


        console.log("periodError on user/cart/cart")
        //this.$emit("periodError");

        //return;
      }

      isNaN(period) ? (this.period = 0) : (this.period = period);
      this.calcFixPrice();
      this.$emit("changePeriod", leftDays);
    },
    calcFixPrice() {
      //console.log("calcFixPrice");
      if (isNaN(this.period) || this.period < 0) {
        return;
      }
      //this.fixPrice = this.totalPrice * this.period;
      //1,2日の価格 = this.totalPrice + after_second_day_price
      //3日目以降の価格
      let after_second_day_price;
      if (this.period > 2) {
        //console.log("3日目以降");
        //console.log(this.period);
        after_second_day_price =
          (this.period - 1) * this.after_second_day_price;
      } else {
        after_second_day_price = this.after_second_day_price;
      }

      //日数を加味した価格
      this.fixPrice = Math.ceil(this.totalPrice + after_second_day_price);
      //税抜価格を算出
      //this.subtotal = Math.ceil(this.fixPrice / 1.1);
      //消費税額を算出
      //this.tax = Math.ceil(this.fixPrice - this.subtotal);

      if (this.attribute == 1) {
        let discountRate = this.cartItem[0].discount; //法人割引率
        this.discount = Math.floor(this.fixPrice / discountRate); //法人割引適用額
        //this.subtotal = this.subtotal - this.discount; //税込小計
        //this.tax = Math.ceil(this.fixPrice - this.subtotal); //消費税額
        this.fixPrice = this.fixPrice - this.discount; //税込総計
      } else {
        this.discount = 0;
      }

      //console.log("period: " + this.period);
      if( this.period > 0) {
        this.model.fix_price = Math.ceil(this.fixPrice);
        this.model.subtotal = this.subtotal;
        this.model.tax = this.tax;
      } else {
        //console.log("periodが0以下");
        this.model.fix_price = 0;
        this.model.subtotal = 0;
        this.model.tax = 0;
        this.discount = 0;
      };
      this.fixPrice = this.model.fix_price;
      this.subtotal = this.model.subtotal;
      this.tax = this.model.tax;

      //propsのpick_up,give_backのどちらかが2の場合は送料を加算
      if (this.pick_up == 2 || this.give_back == 2) {
        this.actualSendPrice = this.send_price;
      } else {
        this.actualSendPrice = 0;
      }
    },
    update(item) {
      this.$emit("update", item);
    },
    deleteItem: function (id) {
      this.$emit("deleteItem", id);
    },
    toggleShowPeriod() {
      console.log("toggleShowPeriod");
      this.start_date = this.model.start_date;
      this.end_date = this.model.end_date;
      this.showPeriod = false;
      this.$nextTick(() => {
        this.showPeriod = true;
      });
    }

  },
  watch: {
    errors: function (newError) {
      this.error = newError;
    },
    cartItem: function () {
      this.calcPeriod();
    },
    attribute: function () {
      this.calcPeriod();
    },
    period: function () {
      console.log("period変更")
      this.calcPeriod();
      this.calcFixPrice();
    },
    start_date: function () {
      console.log("start_date変更")
      this.calcPeriod('start');

      if(this.period <= 0 && this.start_date != '' && this.end_date != '') {
        console.log("start_dateをクリア")
        this.start_date = '';
        this.model.start_date = '';
        this.toggleShowPeriod();
        //document.querySelector('#start_date').value = '';
      }
    },
    end_date: function () {
      console.log("end_date変更")
      this.calcPeriod('end');

      if(this.period <= 0 && this.start_date != '' && this.end_date != '') {
        console.log("end_dateをクリア")
        this.end_date = '';
        this.model.end_date = '';
        this.toggleShowPeriod();
        //document.querySelector('#end_date').value = '';
      }
    },
    pick_up: function () {
      this.calcFixPrice();
    },
    give_back: function () {
      this.calcFixPrice();
    },
  },
};
</script>

<style lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.period-input {
  position: relative;
  input {
    @include mixin.liquidSize(width, 180, 520);
    @include mixin.liquidSize(height, 30, 80);
    @include mixin.liquidSize(padding-bottom, 15, 0);
    @include mixin.liquidSize(margin-right, 15, 20);
    border-bottom: dotted 1px #dedede;
    box-sizing: border-box;
    @include mixin.liquidSize(font-size, 15, 26);
    cursor: pointer;
  }
  &.has-value {
    .vdp-datepicker {
      input {
        background-image: none;
      }
    }
    .delete-btn {
      opacity: 1;
      pointer-events: all;
    }
  }
  .delete-btn {
    position: absolute;
    @include mixin.liquidSize(top, 2, 16);
    @include mixin.liquidSize(right, 63, 94);
    background: #e00;
    background:#6f6f6f;
    color: #fff;
    border-radius: 50%;
    @include mixin.liquidSize(width, 23, 46);
    @include mixin.liquidSize(height, 23, 46);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .4s ease;
    opacity: 0;
    pointer-events: none;
    @include media.min-screen(media.$breakpoint-md) {
      //width: 23px;
      //height: 23px;
      //top: 2px;
      //right: 63px;
    }

    &:hover {
      background: #343434;
    }
  }
}
</style>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
#cart {
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
    align-items: flex-start;
  }
}

#cart-product {

}

.cart-blk {
  @include mixin.liquidSize(width, 954, 700);
  background: #fff;
  box-sizing: border-box;
  @include mixin.liquidSize(padding, 55, 40);
  @include mixin.liquidSize(margin-bottom, 45, 60);
}

.cart-blk__product {

}

.cart-blk__period {

}

.cart-head {
  display: flex;
  @include mixin.liquidSize(font-size, 14, 20);
  .name {
    @include mixin.liquidSize(width, 480, 316);
  }
  .price {
    @include mixin.liquidSize(width, 143, 0);
  }
  .amount {
    @include mixin.liquidSize(width, 144, 224);
  }
  .support {
    @include mixin.liquidSize(width, 77, 80);
    text-align: right;
    white-space: nowrap;
    position: relative;
    .icon {
      @include mixin.liquidSize(width, 22, 22);
      @include mixin.liquidSize(height, 22, 22);
      position: absolute;
      @include mixin.liquidSize(right, -10, -4);
      @include mixin.liquidSize(top, 10, 4);
      transform: translate(100%, 0);
    }
  }
}

.note {
  color: #6f6f6f;
  @include mixin.liquidSize(font-size, 13, 20);
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 32, 32);
  line-height: 1.5;
  position: relative;
  border-bottom: dotted 1px #dedede;
  @include mixin.liquidSize(padding-bottom, 20, 20);
  .icon {
    @include mixin.liquidSize(width, 22, 22);
    @include mixin.liquidSize(height, 22, 22);
    position: absolute;
    left: 0;
    @include mixin.liquidSize(top, -1, 5);
  }
  .head {
    color: #000;
  }
}

.period {
  @include mixin.liquidSize(font-size, 15, 26);
  //@include mixin.liquidSize(margin-top, 30, 30);
  @include mixin.liquidSize(margin-bottom, 10, 10);
  display: flex;
  &.nochange {
    pointer-events: none;
  }
  @include media.max-screen(media.$breakpoint-sm) {
    flex-direction: column;
  }
  &:before {
    content: "必須";
    background: #e57e7e;
    color: #fff;
    white-space: nowrap;
    @include mixin.liquidSize(font-size, 13, 26);
    @include mixin.liquidSize(width, 41, 80);
    @include mixin.liquidSize(height, 20, 40);
    @include mixin.liquidSize(margin-right, 12, 0);
    @include mixin.liquidSize(margin-bottom, 0, 20);
    @include mixin.liquidSize(border-radius, 4, 8);

    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 6, 12);
    @include mixin.liquidSize(padding-right, 6, 12);
    @include media.max-screen(media.$breakpoint-sm) {
      display: block;
    }
    @include media.min-screen(media.$breakpoint-md) {
      display: inline-block;
    }
  }
  .head {
    @include mixin.liquidSize(margin-bottom, 0, 20);
    @include mixin.liquidSize(margin-right, 10, 20);
  }
  .gr {
    display: flex;
    align-items: center;
    @include mixin.liquidSize(margin-bottom, 0, 30);
    .txt {
      color: #6f6f6f;
      @include mixin.liquidSize(margin-right, 20, 0);
      position: relative;
      @include mixin.liquidSize(top, -7, -4);
    }
  }

}



.holiday {
  color: #1890a7;
  @include mixin.liquidSize(font-size, 14, 26);
  @include mixin.liquidSize(line-height, 24, 46);
}


#cart-price {
  @include mixin.liquidSize(width, 350, 700);
  background: #fff;
  box-sizing: border-box;
  @include mixin.liquidSize(padding-top, 45, 70);
  @include mixin.liquidSize(padding-bottom, 30, 60);
  @include mixin.liquidSize(padding-left, 25, 40);
  @include mixin.liquidSize(padding-right, 25, 40);
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: auto;
  }
}

#cart-price {
  //小計、消費税
  .item {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      @include mixin.liquidSize(margin-bottom, 5, 6);
    }
    .head {
      color: #939393;
      @include mixin.liquidSize(font-size, 18, 27);
      font-weight: 500; /* medium */
    }
    .num {
      color: #1890a7;
      margin-left: auto;
      @include mixin.liquidSize(font-size, 26, 32);
      @include mixin.liquidSize(margin-right, 7, 17);
    }
    .currency {
      color: #1890a7;
      @include mixin.liquidSize(font-size, 16, 24);
      @include mixin.liquidSize(margin-top, 5, 6);
    }
    &.discount {
      .head,
      .num,
      .currency {
        color: #d52e2e;
      }
    }
  }

  //合計
  .total {
    display: flex;
    align-items: center;
    @include mixin.liquidSize(margin-top, 25, 30);
    @include mixin.liquidSize(padding-top, 15, 20);
    border-top: dotted 1px #c7c7c7;
    @include mixin.liquidSize(margin-bottom, 5, 5);
    .head {
      @include mixin.liquidSize(font-size, 18, 32);
    }
    .num {
      color: #1890a7;
      @include mixin.liquidSize(font-size, 26, 32);
      @include mixin.liquidSize(margin-right, 7, 17);
      margin-left: auto;
    }
    .currency {
      color: #1890a7;
      @include mixin.liquidSize(font-size, 16, 24);
      @include mixin.liquidSize(margin-top, 5, 6);
    }
  }

  //内消費税
  .tax {
    text-align: right;
    @include mixin.liquidSize(font-size, 16, 24);
    color: #939393;
  }
}
</style>
