<!-- 下層ページのKV下 -->
<template lang="pug">
  .head-blk
    span.head
      span.ja {{ ja }}
      span.en {{ en }}

</template>

<script>
export default {
  name: "head-3",
  props: {
    ja: String,
    en: String,
    desc: String,
    type: String,
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

.head-blk {
  width: 100%;
  @include mixin.liquidSize(margin-top, 65, 100);
}

.head {
}
.ja {
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 21, 40);
  display: inline-block;
  @include mixin.lhFix(1);
  display: block;
}
.en {
  font-weight: 500; /* medium */
  color: #848484;
  @include mixin.liquidSize(font-size, 14, 24);
  @include mixin.lhFix(1);
  display: block;
}
</style>
