<!-- 日英 横並び -->
<template lang="pug">
  #overlay(@click="off" :class="{off: type == 'B'}")
    transition(name="card")
      //- typeA ユーザー画面
      .card.typeA(@click.stop v-if="showCard && type == 'A'")
        .icon
          img(:src="loadImg")
        span.ttl {{ttl}}
        .blk
          p.desc(v-html="msg")
        .btns(:class="{twin: additionalBtn}")
          .btns-item(@click="off") 閉じる
          router-link.btns-item(:to="additionalBtnLink" v-if="additionalBtn") {{additionalBtnTxt}}
      //- typeB ユーザー画面
      .card.typeB(@click.stop v-if="showCard && type == 'B'")
        //-span.head {{head}}
        .icon
          img(:src="loadImg")
        span.ttl(v-html="ttl")
        .blk
          p.desc(v-html="msg")
        .btns(:class="{twin: additionalBtn}")
          a.btns-item.additional(:href="additionalBtnLink" v-if="additionalBtn" target="_blank") {{additionalBtnTxt}}
          .btns-item.close(@click="back2top") 閉じる

      //- エラー ユーザー画面
      .card.typeError(@click.stop v-if="showCard && type == 'error'")
        span.ttl(v-html="ttl")
        .blk
          p.desc(v-html="msg")
        .btns(:class="{twin: additionalBtn}")
          a.btns-item.additional(:href="additionalBtnLink" v-if="additionalBtn" target="_blank") {{additionalBtnTxt}}
          .btns-item.close(@click="off") 閉じる

      //- 管理画面
      .card.typeDeleteItem(@click.stop v-if="showCard && type == 'deleteItem'")
        .icon
          img(:src="loadImg")
        span.ttl {{ttl}}
        .blk
          p.desc(v-html="msg")
          .btn(@click="deleteSubmit") 削除する
        .btns(:class="{twin: additionalBtn}")
          .btns-item(@click="off") 閉じる
          router-link.btns-item(:to="additionalBtnLink" v-if="additionalBtn") {{additionalBtnTxt}}

      //- 管理画面 ログアウ
      .card.typeDeleteItem(@click.stop v-if="showCard && type == 'logout'")
        .icon
          img(:src="loadImg")
        span.ttl {{ttl}}
        .blk
          p.desc(v-html="msg")
          .btn(@click="deleteSubmit") ログアウト
        .btns(:class="{twin: additionalBtn}")
          .btns-item(@click="off") 閉じる
          router-link.btns-item(:to="additionalBtnLink" v-if="additionalBtn") {{additionalBtnTxt}}


</template>

<script>
export default {
  name: "overlay",
  props: {
    type: String,
    head: String,
    icon: String,
    ttl: String,
    msg: String,
    additionalBtn: Boolean,
    additionalBtnLink: String,
    additionalBtnTxt: String,
  },
  data: function () {
    return {
      showCard: false,
      iconName: "",
    };
  },
  computed: {
    loadImg() {
      return require("@/assets/icon-" + this.icon);
    },
  },
  methods: {
    off: function () {
      this.showCard = false;
      let v = this;
      setTimeout(function () {
        v.$emit("overlayOff", false);
      }, 100);
    },
    deleteSubmit: function () {
      this.showCard = false;
      let v = this;
      setTimeout(function () {
        v.$emit("deleteSubmit", true);
      }, 100);
    },
    back2top: function() {
      this.$router.push({ path: '/' });
    }
  },
  mounted() {
    this.showCard = true;
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  &.off {
    background: none;
    @include mixin.liquidSize(top, 100, 100);
  }
}

.card {
  background: #fff;
  @include mixin.liquidSize(border-radius, 13, 10);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  &.typeA {
    @include mixin.liquidSize(padding-top, 50, 100);
    @include media.max-screen(media.$breakpoint-sm) {
      width: 60vw;
    }
    @include media.min-screen(media.$breakpoint-md) {
      //width: 505px;
      width: 300px;
      min-height: 380px;
    }
    .icon {
      @include mixin.liquidSize(width, 84, 120);
      @include mixin.liquidSize(height, 84, 120);
      @include mixin.liquidSize(margin-bottom, 33, 50);
    }
    .btns {
      margin-top: auto;
      display: flex;
      justify-content: center;
      width: 100%;
      &.twin {
        .btns-item {
          width: 50%;
          &:nth-of-type(1) {
            border-right: solid 1px #167c90;
          }
        }
      }
    }
    .btns-item {
      @include mixin.liquidSize(font-size, 16, 28);
      color: #167c90;
      @include mixin.liquidSize(height, 65, 90);
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-top: solid 1px #167c90;
      cursor: pointer;
      width: 100%;
    }
  }
  &.typeB {
    @include mixin.liquidSize(border-radius, 0, 0);
    @include mixin.liquidSize(padding-top, 30, 100);
    @include mixin.liquidSize(padding-bottom, 50, 100);
    width: 100%;
    height: 100%;
    @include media.max-screen(media.$breakpoint-sm) {
      //width: 80vw;
    }
    @include media.min-screen(media.$breakpoint-md) {
      //width: 1073px;
      //width: 620px;
      //min-height: 380px;
    }
    .icon {
      @include mixin.liquidSize(width, 350, 482);
      @include mixin.liquidSize(height, 222, 305);
      @include mixin.liquidSize(margin-bottom, 33, 50);
    }
    .blk {
      @include mixin.liquidSize(width, 550, auto);
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      @include mixin.liquidSize(margin-bottom, 50, 100);
    }
    .btns {
      display: flex;
      justify-content: center;
      @include media.max-screen(media.$breakpoint-sm) {
        flex-direction: column;
        align-items: center;
      }
    }
    .btns-item {
      display: flex;
      justify-content: center;
      align-items: center;
      @include mixin.liquidSize(font-size, 17, 26);
      @include mixin.liquidSize(height, 49, 90);
      letter-spacing: 0.014em;
      font-weight: 500; /* medium */
      &.additional {
        @include mixin.liquidSize(width, 176, 450);
        @include mixin.liquidSize(margin-right, 15, 0);
        @include mixin.liquidSize(margin-bottom, 0, 60);
        color: #000;
        box-sizing: border-box;
        @include mixin.liquidSize(padding-left, 30, 60);
        position: relative;
        border-bottom: solid 1px;
        &:before {
          content: "";
          @include mixin.liquidSize(width, 14, 28);
          @include mixin.liquidSize(height, 20, 40);
          position: absolute;
          @include mixin.liquidSize(left, 8, 16);
          //@include mixin.liquidSize(top, 0, 0);
          top: 50%;
          transform: translate(0, -50%);
          background: {
            image: url(../../assets/icon-download.svg);
            size: contain;
            repeat: no-repeat;
            position: center;
          }
        }
      }
      &.close {
        @include mixin.liquidSize(width, 207, 220);
        @include mixin.liquidSize(margin-left, 15, 0);
        background: #1890a7;
        color: #fff;
        @include mixin.liquidSize(border-radius, 5, 10);
      }
    }
  }
  &.typeError {
    @include mixin.liquidSize(padding-top, 50, 100);
    @include mixin.liquidSize(padding-bottom, 50, 100);
    @include media.max-screen(media.$breakpoint-sm) {
      width: 80vw;
    }
    @include media.min-screen(media.$breakpoint-md) {
      //width: 1073px;
      width: 400px;
      min-height: 200px;
    }
    .blk {
      @include mixin.liquidSize(width, 400, auto);
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      @include mixin.liquidSize(margin-bottom, 70, 100);
    }
    .btns {
      display: flex;
      justify-content: center;
      @include media.max-screen(media.$breakpoint-sm) {
        flex-direction: column;
        align-items: center;
      }
    }
    .btns-item {
      display: flex;
      justify-content: center;
      align-items: center;
      @include mixin.liquidSize(font-size, 17, 26);
      @include mixin.liquidSize(height, 49, 90);
      letter-spacing: 0.014em;
      font-weight: 500; /* medium */
      &.additional {
        @include mixin.liquidSize(width, 176, 450);
        @include mixin.liquidSize(margin-right, 15, 0);
        @include mixin.liquidSize(margin-bottom, 0, 60);
        color: #000;
        box-sizing: border-box;
        @include mixin.liquidSize(padding-left, 30, 60);
        position: relative;
        border-bottom: solid 1px;
        &:before {
          content: "";
          @include mixin.liquidSize(width, 14, 28);
          @include mixin.liquidSize(height, 20, 40);
          position: absolute;
          @include mixin.liquidSize(left, 8, 16);
          //@include mixin.liquidSize(top, 0, 0);
          top: 50%;
          transform: translate(0, -50%);
          background: {
            image: url(../../assets/icon-download.svg);
            size: contain;
            repeat: no-repeat;
            position: center;
          }
        }
      }
      &.close {
        @include mixin.liquidSize(width, 207, 220);
        @include mixin.liquidSize(margin-left, 15, 0);
        background: #1890a7;
        color: #fff;
        @include mixin.liquidSize(border-radius, 5, 10);
      }
    }
  }
  &.typeDeleteItem {
    @include mixin.liquidSize(padding-top, 50, 100);
    @include media.max-screen(media.$breakpoint-sm) {
      width: 60vw;
    }
    @include media.min-screen(media.$breakpoint-md) {
      //width: 505px;
      width: 300px;
      min-height: 300px;
    }
    .icon {
      @include mixin.liquidSize(width, 84, 120);
      @include mixin.liquidSize(height, 84, 120);
      @include mixin.liquidSize(margin-bottom, 33, 50);
    }
    .btns {
      margin-top: auto;
      display: flex;
      justify-content: center;
      width: 100%;
      &.twin {
        .btns-item {
          width: 50%;
          &:nth-of-type(1) {
            border-right: solid 1px #167c90;
          }
        }
      }
    }
    .btns-item {
      @include mixin.liquidSize(font-size, 16, 28);
      color: #167c90;
      @include mixin.liquidSize(height, 65, 90);
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-top: solid 1px #167c90;
      cursor: pointer;
      width: 100%;
    }

    .btn {
      @include mixin.liquidSize(width, 160, 200);
      @include mixin.liquidSize(height, 50, 80);
      background: #d52e2e;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      @include mixin.liquidSize(font-size, 18, 28);
      @include mixin.liquidSize(border-radius, 5, 10);
      margin-left: auto;
      margin-right: auto;
      @include mixin.liquidSize(margin-top, 40, 60);
      @include mixin.liquidSize(margin-bottom, 20, 10);
      cursor: pointer;
    }
  }
}

.card-enter {
  transform: scale(0) translate(0, 0%);
  transition: all 0.3s ease-in-out;
}

.card-enter-to,
.card-leave {
  transform: scale(1) translate(0, 0);
  transition: all 0.3s ease-in-out;
}

.card-leave-to {
  transform: scale(0) translate(0, 0);
  transition: all 0.2s ease-in-out;
}

.head {
  @include mixin.liquidSize(font-size, 24, 36);
  border-bottom: dotted 1px #707070;
  @include mixin.liquidSize(padding-bottom, 4, 8);
  @include mixin.liquidSize(margin-bottom, 30, 60);
}

.ttl {
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 23, 30);
  color: #157c90;
  display: block;
  text-align: center;
  @include mixin.liquidSize(margin-bottom, 20, 40);
  letter-spacing: 0.025em;
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 32, 50);
  @include mixin.liquidSize(padding-right, 32, 50);
}

.blk {
  box-sizing: border-box;
  max-width: 100%;
  @include mixin.liquidSize(padding-left, 20, 50);
  @include mixin.liquidSize(padding-right, 20, 50);
  @include mixin.liquidSize(margin-bottom, 20, 60);
}
.desc {
  @include mixin.liquidSize(font-size, 18, 26);
  text-align: center;
  letter-spacing: 0.02em;
  line-height: calc(31 / 18);
}
</style>
