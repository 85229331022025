import { render, staticRenderFns } from "./itemList.vue?vue&type=template&id=bc1d8d68&scoped=true&lang=pug&"
import script from "./itemList.vue?vue&type=script&lang=js&"
export * from "./itemList.vue?vue&type=script&lang=js&"
import style0 from "./itemList.vue?vue&type=style&index=0&id=bc1d8d68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc1d8d68",
  null
  
)

export default component.exports