<template lang="pug">
  .about
    main.user
      Header
      section.kv
      section.content
        .m-content
          .breadcrumb
            breadcrumb(
              h2txt="カリトクとは"
              h2link=""
              h3txt=""
              h3link=""
            )

          section
            h2.head
              head2(ja="カリトクとは" en="About")

            .logo
              img(src="@/assets/logo-horizontal-2.svg" alt="イベント備品レンタル倉庫カリトク PRODUCED BY NOLAZE")

            h3.copy 「その催し事が問題なく成功すること」が、
              br.m-sm
              |私たちのサービスです。
            .body-blk
              p.body カリトクは
                br.m-sm
                |イベント運営のプロ集団「株式会社ノーレイジ」が運営する、
                br.m-sm
                |イベント備品レンタルサービスです。
              p.body 大規模フェスやコンサート、
                br.m-sm
                |大手企業の社内イベントや大規模会議など、
                br.m-sm
                |これまで数々の催し事を運営してきた私たちだからこそできる、
              p.body 細かいアドバイスや提案が可能なカリトクは、
                br.m-sm
                |従来の貸すだけのサービスではなく、
              p.body お客様の催し事が成功することまで
                br.m-sm
                |一緒にサポートさせていただくことで好評を得ております。


          section.bnrs-blk
            //- バナー
            bnrs(seeing="about")

        contact
        Footer
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import contact from "@/components/user/common/contact.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import head2 from "@/components/common/head-2.vue";
import bnrs from "@/components/user/common/bnrs.vue";
export default {
  name: "About",
  metaInfo: {
    title: "関西でイベント備品のレンタルならカリトクク",
    meta: [
      {
        name: "description",
        content:
          "大阪を中心にイベント備品のレンタルならカリトクをご活用ください。大阪、兵庫、京都、奈良、和歌山でイベント備品を貸出し、配送も対応しております。",
      },
      { name: "keywords", content: "" },
    ],
  },
  components: {
    Header,
    contact,
    Footer,
    breadcrumb,
    head2,
    bnrs,
  },
};
</script>
<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.kv {
  width: 100%;
  @include mixin.liquidSize(height, 193, 188);
  background: {
    image: url(../assets/bg-about.jpg);
    position: center;
    size: cover;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.head {
  @include mixin.liquidSize(margin-bottom, 145, 200);
}

.logo {
  @include mixin.liquidSize(width, 329, 400);
  margin-left: auto;
  margin-right: auto;
  @include mixin.liquidSize(margin-bottom, 120, 100);
}

.copy {
  color: #1890a7;
  @include mixin.liquidSize(font-size, 23, 32);
  text-align: center;
  font-weight: 500; /* medium */
  line-height: 1.75;
  @include mixin.liquidSize(margin-bottom, 90, 100);
}

.body-blk {
  @include mixin.liquidSize(margin-bottom, 160, 200);
}
.body {
  text-align: center;
  @include mixin.liquidSize(font-size, 15, 23);
  line-height: 3.8;
}

.bnrs-blk {
  @include mixin.liquidSize(margin-bottom, 60, 100);
}
</style>
