<template lang="pug">

  main.user
    Header
    section.kv

    section.content
      .m-content
        .breadcrumb
          breadcrumb(
            h2txt="展示会"
            h2link=""
            h3txt=""
            h3link=""
          )

        section
          h2.head
            head2(ja="展示会" en="Exhibition")
          p.desc
            |各種展示イベントに最適なイベント備品を取り揃えております。

        itemList(
          ja="椅子"
          en="Chair"
          keyword="chair"
          :list="chair"
        )

        itemList(
          ja="テーブル"
          en="Table"
          keyword="table"
          :list="table"
        )

        itemList(
          ja="夏季商品"
          en="Summer"
          keyword="summer"
          :list="summer"
        )

        itemList(
          ja="冬季商品"
          en="Winter"
          keyword="winter"
          :list="winter"
        )

        itemList(
          ja="ステージ・スロープ"
          en="Stage / Slope"
          keyword="stage"
          :list="stage"
        )

        itemList(
          ja="電灯・照明・電気機器"
          en="Light"
          keyword="light"
          :list="light"
        )

        itemList(
          ja="イベント用品・その他"
          en="Event / Other"
          keyword="event"
          :list="event"
        )

        itemList(
          ja="会場整理用品"
          en="Venue"
          keyword="venue"
          :list="venue"
        )

        itemList(
          ja="抽選機・玉"
          en="Lottery"
          keyword="lottery"
          :list="lottery"
        )

        itemList(
          ja="敷物類シート関連"
          en="Rag"
          keyword="rag"
          :list="rag"
        )

        itemList(
          ja="パネル・パーテンション"
          en="Panel"
          keyword="panel"
          :list="panel"
        )












    section#scene
      .m-content
        h3.head
          head1(ja="シーン別に探す" en="Scene" type="h")
      scene

    section#flow
      flow
    contact
    Footer
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import flow from "@/components/user/common/flow.vue";
import contact from "@/components/user/common/contact.vue";
import scene from "@/components/user/common/scene.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import head2 from "@/components/common/head-2.vue";
import bnrs from "@/components/user/common/bnrs.vue";
import head1 from "@/components/common/head-1.vue";
import ApiClient from "@/lib/http.js";
import itemList from "@/components/user/scene/itemList.vue";
export default {
  name: "Festival",
  metaInfo() {
    return {
      title: "展示会を開催するならカリトクで備品レンタル",
      meta: [
        { name: "description", content: "カリトクはイベント運営のプロ集団「株式会社ノーレイジ」が運営する、イベント備品レンタルサービスです。大規模フェスやコンサート、大手企業の社内イベントや大規模会議など、これまで数々の催し事を運営してきた私たちだからこそできる、細かいアドバイスや提案が可能なカリトクは、従来の貸すだけのサービスではなく、お客様の催し事が成功することまで一緒にサポートさせていただくことで好評を得ております。" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    contact,
    Footer,
    breadcrumb,
    head2,
    bnrs,
    flow,
    scene,
    head1,
    itemList,
  },
  data: function () {
    return {
      chair: [],
      table: [],
      summer: [],
      winter: [],
      stage: [],
      light: [],
      event: [],
      venue: [],
      lottery: [],
      rag: [],
      panel: [],
    };
  },
  computed: {
    loadImg(path) {
      return require("@/assets/" + path);
    },
  },
  async created() {
    this.getChair();
    this.getTable();
    this.getSummer();
    this.getWinter();
    this.getStage();
    this.getLight();
    this.getEvent();
    this.getVenue();
    this.getLottery();
    this.getRag();
    this.getPanel();
  },
  methods: {
    async getChair() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "CP-001",
          "CP-002",
          "CP-004",
          "CP-005",
          "CP-006",
          "CP-007",
          "CP-008",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.chair = data.list;
    },
    async getTable() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "TB-001",
          "TB-002",
          "TB-003",
          "TB-004",
          "TB-005",
          "TB-008",
          "TB-014",
          "TB-009",
          "TB-012",
          "TB-010",
          "TB-013",
          "TB-011",
          "TB-999",
          "TB-201",
          "TB-301",
          "TB-302",
          "TB-303",
          "TK-001",
          "TK-002",
          "TK-003",
          "TK-004",
          "TK-101",
          "TB-057",
          "TB-058",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.table = data.list;
    },
    async getSummer() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "SS-002",
          "SS-003",
          "SS-004",
          "SS-051",
          "SS-052",
          "SS-053",
          "SS-054",
          "SS-055",
          "SS-101",
          "SS-102",
          "IB-462",
          "IB-455",
          "IB-456",
          "IB-457",
          "IB-458",
          "BI-459",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.summer = data.list;
    },
    async getWinter() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "WS-001",
          "WS-002",
          "WS-003",
          "WS-020",
          "WS-030",
          "WS-050",
          "WS-051",
          "WS-052",
          "WS-060",
          "WS-061",
          "WS-070",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.winter = data.list;
    },
    async getStage() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "ST-101",
          "ST-102",
          "ST-103",
          "ST-104",
          "ST-091",
          "ST-092",
          "ST-093",
          "ST-094",
          "ST-001",
          "ST-002",
          "ST-003",
          "ST-100",
          "ST-099",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.stage = data.list;
    },
    async getLight() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "DS-010",
          "DK-002",
          "DK-004",
          "DL-030",
          "DL-031",
          "DL-032",
          "DK-005",
          "IB-601",
          "IB-602",
          "IB-603",
          "DS-011",
          "DK-006",
          "DK-009",
          "DK-008",
          "DK-010",
          "DK-020",
          "DK-023",
          "DK-024",
          "DS-001",
          "DS-002",
          "DS-003",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.light = data.list;
    },
    async getEvent() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "CB-020",
          "CB-021",
          "CB-025",
          "CB-026",
          "CB-052",
          "CB-053",
          "CB-051",
          "CB-050",
          "AT-007",
          "AT-008",
          "AT-009",
          "AT-010",
          "IB-009",
          "IB-026",
          "IB-025",
          "IB-506",
          "IB-507",
          "CS-011",
          "IB-410",
          "IB-412",
          "IB-411",
          "IB-451",
          "IB-452",
          "IB-453",
          "IB-454",
          "IB-460",
          "IB-461",
          "IB-459",
          "IB-200",
          "IB-201",
          "IB-202",
          "IB-203",
          "IB-204",
          "CB-054",
          "IB-090",
          "IB-091",
          "IB-092",
          "IB-008",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.event = data.list;
    },
    async getVenue() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "IB-001",
          "IB-002",
          "IB-003",
          "IB-004",
          "IB-005",
          "IB-021",
          "IB-999",
          "IB-070",
          "IB-071",
          "IB-072",
          "IB-073",
          "IB-074",
          "IB-027",
          "IB-503",
          "IB-060",
          "IB-061",
          "IB-062",
          "IB-301",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.venue = data.list;
    },
    async getLottery() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "IB-300",
          "IB-302",
          "IB-303",
          "IB-304",
          "IB-305",
          "IB-306",
          "IB-307",
          "IB-308",
          "IB-309",
          "IB-310",
          "IB-311",
          "IB-312",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.lottery = data.list;
    },
    async getRag() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: ["PK-101", "PK-001", "IB-041", "IB-040", "IB-007"],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.rag = data.list;
    },
    async getPanel() {
      let param = {
        //item_code: "CP-001",
        //category_id: "2"
        item_code: [
          "IB-501",
          "IB-502",
          "IB-508",
          "IB-509",
          "IB-504",
          "IB-505",
          "CB-101",
          "CB-102",
          "CB-103",
        ],
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.panel = data.list;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.kv {
  width: 100%;
  @include mixin.liquidSize(height, 193, 100);
  background: {
    image: url("../../assets/img-exhibition.jpg");
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}
.head {
  @include mixin.liquidSize(margin-bottom, 30, 45);
}

.desc {
  line-height: 2;
  @include mixin.liquidSize(font-size, 15, 26);
  @include mixin.liquidSize(margin-bottom, 70, 60);
}

//-シーン別に探す
#scene {
  @include mixin.liquidSize(padding-top, 120, 120);
  @include mixin.liquidSize(padding-bottom, 60, 50);
}
//取引の流れ
#flow {
  @include mixin.liquidSize(padding-top, 60, 50);
}
</style>
