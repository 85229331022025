export const common = {
  methods: {
    back() {
      if (this.backURL) {
        this.$router.push(this.backURL);
        return;
      }
      history.back();
    },
    windowTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
